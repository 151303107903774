import React, {Component} from "react";
import {Button, Loading, Modal, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../../../../redux";
import Sidebar from "../../../../../../components/navigation/sidebar";
import {Formik} from "formik";
import {request} from "../../../../../../utils/request";
import {parseIdDict} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../../../utils/notification-helper";
import {RecipeForm} from "../../../../../../forms/operations/supply/recipe-form";
import {CUSTOMIZATION_TYPES} from "@frostbyte-technologies/frostbyte-tickets/dist/constants/product-constants";
import {RecipeRequests} from "../../../../../../utils/request-helpers/supply-chain/supply-chain-requests";

class MigrateMenuModal extends Component {
  state = {current: null, products: null, modifiers: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const productsRequest = request("products/", "GET");
    const modifiersRequest = request("modifiers/presets", "GET");

    const [serverProducts, serverModifiers] = await Promise.all([
      productsRequest,
      modifiersRequest,
    ]);

    const {products, modifiers} = this.refreshIngredients(
      serverProducts,
      serverModifiers
    );

    this.setState((prevState) => {
      const {current} = prevState;
      let serverCurrent = current;

      if (current && "PRODUCT_ID" in current) {
        serverCurrent = products.filter((product) => product["PRODUCT_ID"] === current["PRODUCT_ID"])[0];
      } else if (current && "OPTION_ID" in current) {
        serverCurrent = modifiers.flatMap((modifier) => modifier.CHILDREN).filter((option) => option["OPTION_ID"] === current["OPTION_ID"])[0];
      }

      return {products, modifiers, current: serverCurrent};
    });
  }

  refreshIngredients(products, modifiers) {
    const {ingredients} = this.props.supply;
    const ingredientNameDict = parseIdDict(ingredients, "NAME");
    const ingredientIdDict = parseIdDict(ingredients, "ID");

    const transformedProducts = products.map((_product) => {
      return this.transformProductToEntity(
        _product,
        ingredientNameDict,
        ingredientIdDict
      );
    });

    const transformedModifiers = modifiers.flatMap((_modifier) => {
      return this.transformModifierToEntity(
        _modifier,
        ingredientNameDict,
        ingredientIdDict
      );
    });

    return {products: transformedProducts, modifiers: transformedModifiers};
  }

  transformProductToEntity(product, ingredientNameDict, ingredientIdDict) {
    const namedIngredient = ingredientNameDict[product.NAME];
    const idIngredient = product.RECIPES?.find((r) => ingredientIdDict[r.ID]);
    const ingredient = namedIngredient ?? ingredientIdDict[idIngredient?.ID];

    return {
      NAME: product.NAME,
      PRODUCT_ID: product.ID,
      ID: ingredient?.ID,
      ICON: product.RECIPES?.length > 0 && (
        <FontAwesomeIcon icon="check-circle" color="green" />
      ),
      uid: product.ID,
      product,
      ingredient,
    };
  }

  transformModifierToEntity(modifier, ingredientDict) {
    const modifiers = modifier.OPTIONS.map((option) => {
      const ingredient = ingredientDict[option.NAME];

      return {
        NAME: `${option.NAME}`,
        OPTION_ID: option.ID,
        ID: ingredient?.ID,
        ICON: option.RECIPES?.length > 0 && (
          <FontAwesomeIcon icon="check-circle" color="green" />
        ),
        uid: option.ID,
        option: {...option, TYPE: modifier.TYPE},
        ingredient,
      };
    });

    return {
      NAME: `${modifier.NAME}`,
      ICON: modifiers.every((option) => option.RECIPES?.length > 0) ? (
        <FontAwesomeIcon icon="check-circle" color="green" />
      ) : null,
      CHILDREN: modifiers,
    };
  }

  fetchRecipePayload(values) {
    const {ingredient, quantity, option} = values;
    const {current} = this.state;

    const payload = {
      INGREDIENT_ID: ingredient,
      QUANTITY: quantity,
      PRODUCT_ID: current.PRODUCT_ID,
    };

    if (current.option && current.option.TYPE === CUSTOMIZATION_TYPES.VARIANT) {
      payload.VARIANT_OPTION_ID = current.option.ID;
    } else if (current.option) {
      payload.OPTION_ID = current.option.ID;
      payload.VARIANT_OPTION_ID = option;
    }

    return payload;
  }

  async sendCreateRecipeRequest(ingredient, payload) {
    try {
      await RecipeRequests.attachIngredient(ingredient, payload);
      await this.syncState();
      showSuccessNotification("Recipe created successfully.");
    } catch (e) {
      showErrorNotification("Error creating recipe.");
    }
  }

  async createRecipe(values) {
    const {ingredient} = values;
    const payload = this.fetchRecipePayload(values);
    return this.sendCreateRecipeRequest(ingredient, payload);
  }

  async submit(values) {
    this.submitButton.startLoading();
    const result = await this.createRecipe(values);
    await this.syncState();
    this.submitButton.stopLoading();
    return result;
  }

  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  fetchIngredientValidation() {
    return Yup.number().nullable().required("Please select an ingredient.");
  }

  fetchQuantityValidation() {
    return Yup.number()
      .positive()
      .nullable()
      .required("Please enter a positive quantity.");
  }

  fetchOptionValidation() {
    return Yup.number().nullable();
  }

  fetchValidationSchema() {
    return Yup.object().shape({
      ingredient: this.fetchIngredientValidation(),
      quantity: this.fetchQuantityValidation(),
      option: this.fetchOptionValidation(),
    });
  }

  fetchInitialValues() {
    return {
      ingredient: null,
      quantity: 0,
      variant: null,
      option: null,
    };
  }

  fetchProductsNavigation() {
    const {products} = this.state;

    return {
      NAME: "Products",
      CHILDREN: products,
    };
  }

  fetchModifiersNavigation() {
    const {modifiers} = this.state;

    return {
      NAME: "Global Modifiers",
      CHILDREN: modifiers,
    };
  }

  renderSidebar() {
    return (
      <Sidebar
        className="col-span-1 mr-4"
        onChange={(current) => {
          return this.setState(
            {current},
            () => this.formikRef && this.formikRef.resetForm()
          );
        }}
        navigation={[this.fetchProductsNavigation(), this.fetchModifiersNavigation()]}
      />
    );
  }

  renderSubmitButton(formikOptions) {
    return (
      <div className="flex justify-end pr-4 pb-2">
        <Button
          ref={(e) => (this.submitButton = e)}
          onClick={formikOptions.handleSubmit}
          label="Create"
        />
      </div>
    );
  }

  renderRecipeCreation(formikOptions) {
    const {current} = this.state;
    const ingredient = current?.ingredient;

    return (
      <div className="shadow-md rounded-md mt-4">
        <div className="p-4 rounded-t-md font-semibold bg-gray-100">
          Setup Recipe {current ? `for ${current.NAME}` : ""}
        </div>

        <div className="p-4">
          <RecipeForm
            formikOptions={formikOptions}
            ingredient={ingredient}
            entity={current}
          />
        </div>

        {this.renderSubmitButton(formikOptions)}
      </div>
    );
  }

  renderRecipeForm() {
    return (
      <Formik
        enableReinitialize
        onSubmit={(values) => this.submit(values)}
        initialValues={this.fetchInitialValues()}
        innerRef={(e) => (this.formikRef = e)}
        validationSchema={this.fetchValidationSchema()}
      >
        {(formikOptions) => {
          return (
            <div className="col-span-3 mb-4">
              {this.renderRecipeCreation(formikOptions)}
            </div>
          );
        }}
      </Formik>
    );
  }

  renderRecipeTable() {
    const {current} = this.state;

    if (!current) {
      return <div />;
    }

    return (
      <Table
        data={current.product?.RECIPES || current.option?.RECIPES || []}
        columns={[
          {value: "NAME", label: "Name"},
          {value: "RECIPE_COST", label: "Quantity"},
          {value: "PRODUCT_ID", label: "Product", format: (_, row) => row.PRODUCT?.NAME},
          {value: "OPTION_ID", label: "Option", format: (_, row) => row.OPTION?.NAME},
          {
            value: "VARIANT_OPTION_ID",
            label: "Variant",
            format: (_, row) => row.VARIANT_OPTION?.NAME,
          },
        ]}
      />
    );
  }

  renderBody() {
    return (
      <div className="col-span-3">
        {this.renderRecipeForm()}
        {this.renderRecipeTable()}
      </div>
    );
  }

  render() {
    const {modifiers, products} = this.state;

    if (!modifiers || !products) {
      return <Loading />;
    }

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        full
        backgroundClose={false}
        closeLabel="Close"
      >
        <div className="grid grid-cols-4 h-70vh">
          {this.renderSidebar()}
          {this.renderBody()}
        </div>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop", "supply"])(MigrateMenuModal);
