import React, {Component} from "react";
import {
  Button,
  FormInput,
  Modal,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import Product from "./product";
import IngredientDropdown from "../../../../../dropdowns/operations/recipes/ingredient-dropdown";
import {Formik} from "formik";
import * as Yup from "yup";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../../utils/notification-helper";
import {RecipeRequests} from "../../../../../utils/request-helpers/supply-chain/supply-chain-requests";

class SetupProductIngredientsModal extends Component {
  state = {
    product: null,
    selectedOption: null,
    selectedVariant: null,
    ingredients: [],
    ingredient: null,
  };

  open(product) {
    const ingredients = product.RECIPES.reduce(
      (accum, recipe) => [
        ...accum,
        {
          option: recipe.OPTION,
          variant: recipe.VARIANT_OPTION,
          ingredient: recipe,
          quantity: recipe.RECIPE_COST,
        },
      ],
      []
    );

    this.setState({product, ingredients}, () => this.modal.open());
  }

  saveIngredients = async () => {
    const {ingredients, product} = this.state;
    const payload = [];
    const { handleRecipeSync } = this.props;

    for (const _ingredient of ingredients) {
      const {option, variant, ingredient, quantity} = _ingredient;

      payload.push({
        OPTION_ID: option?.ID,
        VARIANT_OPTION_ID: variant?.ID,
        INGREDIENT_ID: ingredient?.ID,
        PRODUCT_ID: product.ID,
        QUANTITY: parseFloat(quantity),
      });
    }

    try {
      await RecipeRequests.attachRecipe(product.ID, { RECIPES: payload });
      showSuccessNotification(
        "Recipe saved successfully.",
        "The product's recipes were saved successfully."
      );
    } catch (err) {
      showErrorNotification(
        "Error saving recipe.",
        "There was an error saving the recipe. Please try again."
      );
    } finally {
      await handleRecipeSync();
      this.modal.close();
    }
  }

  render() {
    const {product, selectedOption, selectedVariant, ingredient, ingredients} =
      this.state;

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        full
        noPadding
        scrollable={false}
        buttonOnClick={() => this.saveIngredients()}
        buttonLabel="Submit"
      >
        <div className="grid grid-cols-2 sm:h-70vh">
          <Product
            product={product}
            onOptionChange={(selectedOption) => {
              this.setState((prevState) => {
                const {selectedOption: prevSelectedOption} = prevState;

                if (prevSelectedOption?.ID === selectedOption.ID) {
                  return {selectedOption: null};
                }

                return {selectedOption};
              });
            }}
            onVariantChange={(selectedVariant) => {
              this.setState((prevState) => {
                const {selectedVariant: prevSelectedVariant} = prevState;

                if (prevSelectedVariant?.ID === selectedVariant?.ID) {
                  return {selectedVariant: null};
                }

                return {selectedVariant};
              });
            }}
            selectedOption={selectedOption}
            selectedVariant={selectedVariant}
          />

          <div className="bg-white">
            <div className="mb-4 px-4">
              <Formik
                innerRef={(e) => (this.formikRef = e)}
                validationSchema={Yup.object().shape({
                  ingredient: Yup.string()
                    .nullable()
                    .required("Please select an ingredient"),
                  quantity: Yup.number()
                    .positive()
                    .nullable()
                    .required("Please enter a number greater than 0"),
                })}
                initialValues={{
                  ingredient: null,
                  quantity: 0,
                }}
              >
                {(formikOptions) => {
                  const {values} = formikOptions;

                  return (
                    <div className="space-y-3">
                      <IngredientDropdown
                        label="Ingredient"
                        name="ingredient"
                        value={ingredient}
                        options={formikOptions}
                        onChangeSoft={({ingredient}) => {
                          this.setState({ingredient});
                        }}
                      />

                      <FormInput
                        label={`Quantity ${
                          ingredient?.UNIT.NAME ? `in ${ingredient.UNIT.NAME}` : ""
                        }`}
                        name="quantity"
                        options={formikOptions}
                        className="flex-3"
                      />

                      <Button
                        label="Add"
                        onClick={() => {
                          this.setState((prevState) => {
                            if (!ingredient || values.quantity <= 0) {
                              return;
                            }

                            const {ingredients: prevIngredients} = prevState;

                            return {
                              ingredients: [
                                ...prevIngredients,
                                {
                                  option: selectedOption,
                                  variant: selectedVariant,
                                  ingredient: ingredient,
                                  quantity: values.quantity,
                                },
                              ],
                            };
                          });
                        }}
                      />
                    </div>
                  );
                }}
              </Formik>
            </div>

            <Table
              data={ingredients}
              columns={[
                {
                  value: "variant",
                  label: "Variant",
                  format: (_var) => _var?.NAME ?? "Product",
                },
                {
                  value: "option",
                  label: "Option",
                  format: (opt) => opt?.NAME ?? "All Options",
                },
                {
                  value: "ingredient",
                  label: "Ingredient",
                  format: (ing) => {
                    return ing.NAME;
                  },
                },
                {
                  value: "quantity",
                  label: "Quantity",
                  editable: true,
                  onChange: (index, val) => {
                    this.setState((prevState) => {
                      const {ingredients: prevIngredients} = prevState;

                      return {
                        ingredients: [
                          ...prevIngredients.slice(0, index),
                          {...prevIngredients[index], quantity: parseFloat(val)},
                          ...prevIngredients.slice(index + 1),
                        ],
                      };
                    });
                  },
                },
                {
                  value: "ingredient",
                  label: "Unit",
                  format: (ing) => {
                    return ing.UNIT_NAME;
                  },
                },
              ]}
              actionButtons={[
                {
                  label: "Delete",
                  onClick: (row, index) => {
                    this.setState((prevState) => {
                      const {ingredients: prevIngredients} = prevState;

                      return {
                        ingredients: [
                          ...prevIngredients.slice(0, index),
                          ...prevIngredients.slice(index + 1),
                        ],
                      };
                    });
                  },
                },
              ]}
              hideBorder
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default SetupProductIngredientsModal;
