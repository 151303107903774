import React, {Component} from "react";
import {
    Card,
    Loading,
    PageHeadings,
    Tab,
    Table,
    TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import IngredientModal, {
    CALCULATION_TYPE_DISPLAYS, INGREDIENT_TYPE_DISPLAYS, INGREDIENT_TYPES,
} from "../../../../modals/operations/supply-chain/recipes/ingredient-modal";
import AttachIngredientModal from "../../../../modals/operations/supply-chain/recipes/attach-ingredient-modal";
import StockIngredientModal from "../../../../modals/operations/supply-chain/recipes/stock-ingredient-modal";
import moment from "moment-timezone";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import RecipeTable from "../../../../tables/operations/recipes/recipe-table";
import RecipeUnitModal from "../../../../modals/operations/supply-chain/recipes/recipe-unit-modal";
import GrayBadge from "../../../../components/badges/gray-badge";
import ActionButton from "../../../../components/buttons/action-button";
import {setupReduxConnection} from "../../../../redux";
import {View} from "@react-pdf/renderer";
import QuickStockIngredientModal from "../../../../modals/operations/supply-chain/recipes/quick-stock-ingredient-modal";
import IngredientRulesModal from "../../../../modals/operations/supply-chain/recipes/ingredient-rules-modal";
import cronstrue from "cronstrue";

class RecipePage extends Component {
    ADD_STOCK_TYPES = ["RECIPE", "QUICK_ADD"];

    includeModals(ingredient) {
        return (
            <View>
                <AttachIngredientModal
                    ref={(e) => (this.attachIngredientModal = e)}
                    syncState={() => this.syncState()}
                    source={ingredient}
                />

                <IngredientModal
                    ref={(e) => (this.ingredientModal = e)}
                    syncState={() => this.syncState()}
                />

                <StockIngredientModal
                    ref={(e) => (this.stockIngredientModal = e)}
                    ingredient={ingredient}
                    syncState={() => this.syncState()}
                />

                <QuickStockIngredientModal
                    ref={(e) => (this.quickStockIngredientModal = e)}
                    syncState={() => this.syncState()}
                />

                <RecipeUnitModal
                    ref={(e) => (this.unitModal = e)}
                    ingredient={ingredient}
                    syncState={() => this.syncState()}
                />

                <IngredientRulesModal
                    ref={(e) => (this.ingredientRulesModal = e)}
                    ingredient={ingredient}
                />
            </View>
        );
    }

    renderPageHeader(ingredient) {
        return (
            <PageHeadings
                label={ingredient.NAME}
                breadcrumbs={[
                    {label: "Supply Chain", url: "/supply"},
                    {label: "Items/Ingredients", url: "/supply-chain-inventory"},
                ]}
            />
        );
    }

    fetchCalculationTypeField(ingredient) {
        return {
            label: "Calculation Type",
            value: CALCULATION_TYPE_DISPLAYS[ingredient.CALCULATION_TYPE],
        };
    }

    fetchIngredientTypeField(ingredient) {
        return {label: "Type", value: INGREDIENT_TYPE_DISPLAYS[ingredient.TYPE] ?? INGREDIENT_TYPE_DISPLAYS.ITEM};
    }

    fetchIngredientPARField(ingredient) {
        return {
            label: "PAR",
            value: ingredient?.PAR
                ? ingredient.PAR + " " + ingredient.UNIT_NAME
                : <GrayBadge className="font-semibold ml-1">Disabled</GrayBadge>,
        };
    }

    fetchIngredientStockField(ingredient) {
        return {
            label: "Current Inventory",
            value: `${ingredient.DISPLAY_STOCK} ${ingredient.DISPLAY_UNIT.NAME}`,
        };
    }

    fetchIngredientUnitDisplay(ingredient) {
        return (
            <div>
                {ingredient.UNIT_NAME}
                <GrayBadge className="font-semibold ml-1">Measurement</GrayBadge>
            </div>
        );
    }

    fetchIngredientDisplayUnitDisplay(ingredient) {
        return (
            <div>
                {ingredient.DISPLAY_UNIT.NAME}
                <GrayBadge className="font-semibold ml-1">Display</GrayBadge>
            </div>
        );
    }

    fetchIngredientUnitsDisplay(ingredient) {
        return (
            <div className="space-y-2">
                {this.fetchIngredientUnitDisplay(ingredient)}
                {this.fetchIngredientDisplayUnitDisplay(ingredient)}
            </div>
        );
    }

    fetchIngredientUnitsField(ingredient) {
        return {
            label: "Units",
            value: this.fetchIngredientUnitsDisplay(ingredient),
        };
    }

    fetchGroupsField(ingredient) {
        return {
            label: "Groups",
            value: ingredient.CATEGORIES.map((category) => category.NAME).join(", "),
        };
    }

    fetchExpirationField(ingredient) {
        return {
            label: "Expiration",
            value: ingredient.TIME_TO_EXPIRATION
                ? `${ingredient.TIME_TO_EXPIRATION} minutes`
                : <GrayBadge className="font-semibold ml-1">Disabled</GrayBadge>,
        };
    }

    fetchIngredientInformationFields(ingredient) {
        return [
            this.fetchIngredientTypeField(ingredient),
            this.fetchIngredientPARField(ingredient),
            this.fetchIngredientStockField(ingredient),
            this.fetchIngredientUnitsField(ingredient),
            this.fetchGroupsField(ingredient),
            this.fetchExpirationField(ingredient),
        ];
    }

    fetchQuickStockButton(ingredient) {
        return {
            label: "Quick Stock",
            onClick: () => this.quickStockIngredientModal.open(ingredient),
        };
    }

    fetchEditButton(ingredient) {
        return {
            label: "Edit",
            onClick: () => this.ingredientModal.open(ingredient),
        };
    }

    fetchItemActionButtons(ingredient) {
        return [this.fetchQuickStockButton(ingredient), this.fetchEditButton(ingredient)];
    }

    fetchIngredientActionButtons(ingredient) {
        return [this.fetchCreateProductionEvent(ingredient), this.fetchQuickStockButton(ingredient), this.fetchEditButton(ingredient)];
    }

    fetchIngredientInformationTooltip() {
        return {data: "Ingredient configuration"};
    }

    renderIngredientInformation(ingredient) {
        const actionButtons = ingredient.TYPE === INGREDIENT_TYPES.ITEM
            ? this.fetchItemActionButtons(ingredient)
            : this.fetchIngredientActionButtons(ingredient)
        return (
            <TwoColumnList
                label={INGREDIENT_TYPE_DISPLAYS[ingredient.TYPE]}
                tooltip={this.fetchIngredientInformationTooltip()}
                data={this.fetchIngredientInformationFields(ingredient)}
                buttons={actionButtons}
            />
        );
    }

    fetchTabs(ingredient) {
        const {TYPE} = ingredient
        const baseTabs = [
            {id: "production", label: "Activity Log"},
            {id: "pricing", label: "Vendor Items"},
            {id: "rules", label: "Rules"},
        ]
        const ingredientTabs = [
            {id: "recipe", label: "Sub-Recipe"},
        ]
        return TYPE === INGREDIENT_TYPES.ITEM ? baseTabs : [...baseTabs, ...ingredientTabs];
    }

    fetchUnitTabButtons() {
        return [
            {
                label: "Add Unit",
                onClick: () => {
                    this.unitModal.open();
                },
            },
        ];
    }

    fetchUnitNameColumn() {
        return {
            label: "Unit Name",
            value: "NAME",
        };
    }

    formatConversion(val, row, ingredient) {
        const conversion = row.CONVERSIONS?.find(
            (unit) => unit.TO_UNIT === ingredient.UNIT_ID
        );

        return conversion ? `${conversion.RATE} ${ingredient.UNIT.NAME}` : "";
    }

    fetchConversionRateColumn(ingredient) {
        return {
            label: "Conversion Rate",
            value: "RATE",
            format: (val, row) => {
                return this.formatConversion(val, row, ingredient);
            },
        };
    }

    fetchEditUnitColumn() {
        return {
            label: "Edit",
            width: 1,
            value: "NAME",
            format: (val, row) => {
                return <ActionButton label="Edit" onClick={() => this.unitModal.open(row)}/>;
            },
        };
    }

    fetchUnitTableColumns(ingredient) {
        return [
            this.fetchUnitNameColumn(),
            this.fetchConversionRateColumn(ingredient),
            this.fetchEditUnitColumn(),
        ];
    }

    renderUnitTable(ingredient) {
        return (
            <Table
                key="units"
                data={ingredient.UNITS}
                columns={this.fetchUnitTableColumns(ingredient)}
            />
        );
    }

    renderUnitTab(ingredient) {
        return (
            <Card label="Units" buttons={this.fetchUnitTabButtons()}>
                {this.renderUnitTable(ingredient)}
            </Card>
        );
    }

    fetchRecipeTabButtons() {
        return [
            {
                label: "Add Ingredient",
                onClick: () => {
                    this.attachIngredientModal.open();
                },
            },
        ];
    }

    renderRecipeTab(ingredient) {
        return (
            <Card label="Sub-Recipe" buttons={this.fetchRecipeTabButtons()}>
                <RecipeTable ingredient={ingredient} syncState={() => this.syncState()}/>
            </Card>
        );
    }

    fetchVendorNameColumn() {
        return {label: "Vendor", value: "VENDOR_NAME"};
    }

    fetchItemPriceColumn() {
        return {
            label: "Price Per Case",
            value: "PRICE_PER_CASE",
            type: "dollars",
        };
    }

    fetchCaseSizeColumn() {
        return {label: "Vendor Item Quantity", value: "CASE_SIZE"};
    }

    fetchPricingColumns() {
        return [
            this.fetchVendorNameColumn(),
            this.fetchItemPriceColumn(),
            this.fetchCaseSizeColumn(),
        ];
    }

    renderPricingTable(ingredient) {
        return (
            <Table
                key="pricing"
                data={ingredient.VENDOR_ITEMS ?? []}
                columns={this.fetchPricingColumns()}
            />
        );
    }

    renderPricingTab(ingredient) {
        return (
            <Card
                label="Vendor Items"
                description="A list of all of the purchase options for this ingredient"
            >
                {this.renderPricingTable(ingredient)}
            </Card>
        );
    }

    activityTypeDisplay(type) {
        switch (type) {
            case "RECIPE": return "Recipe";
            case "QUICK_ADD": return "Quick Stock";
            case "WASTE": return "Waste";
            case "COUNT": return "Count";
            case "TRANSFER": return "Transfer";
            case "SALE": return "Sale";
            default: return "N/A";
        }
    }

    fetchProductionTypeColumn() {
        return {
            label: "Type",
            value: "TYPE",
            format: (value) => this.activityTypeDisplay(value),
        };
    }

    activityQuantityDisplay(quantity, row, ingredient) {
        if (!this.ADD_STOCK_TYPES.includes(row.TYPE)) {
            quantity = quantity * -1;
        }
        return quantity + " " + ingredient.UNIT.NAME;
    }

    fetchProductionQuantityColumn(ingredient) {
        return {
            label: "Quantity",
            value: "INITIAL_QUANTITY",
            format: (val, row) => this.activityQuantityDisplay(val, row, ingredient),
        };
    }

    fetchProductionCostColumn() {
        return {
            label: "Cost per Unit",
            value: "COST",
            format: (val) => {
                return toDollars(val, true);
            },
        };
    }

    fetchProductionCreationDateColumn() {
        return {
            label: "Activity On",
            value: "DATE_CREATED",
            format: (val) => moment(val).format("MMM Do hh:mmA"),
        };
    }

    fetchProductionEmployeeNameColumn() {
        return {
            label: "Employee",
            value: "FULL_NAME",
        };
    }

    fetchProductionTableColumns(ingredient) {
        return [
            this.fetchProductionTypeColumn(),
            this.fetchProductionQuantityColumn(ingredient),
            this.fetchProductionCostColumn(),
            this.fetchProductionCreationDateColumn(),
            this.fetchProductionEmployeeNameColumn(),
        ];
    }

    renderProductionTable(ingredient) {
        const activityData = ingredient.STOCKS.concat(ingredient.USAGES).sort((a,b) => b.DATE_CREATED - a.DATE_CREATED);
        return (
            <Table
                key="activity"
                data={activityData}
                columns={this.fetchProductionTableColumns(ingredient)}
                pagination
                rowsPerPage
            />
        );
    }

    fetchCreateProductionEvent(ingredient) {
        return {
            label: "Create Production Event",
            onClick: () => {
                this.stockIngredientModal.open(ingredient);
            },
        }
    }

    renderProductionTab(ingredient) {
        return (
            <Card
                label="Activity Log"
            >
                {this.renderProductionTable(ingredient)}
            </Card>
        );
    }

    fetchRulesTabButtons() {
        return [
            {
                label: "Create Rule",
                onClick: () => this.ingredientRulesModal.open(),
            },
        ];
    }

    formatCron(cron) {
        if (!cron) {
            return "";
        }

        return cronstrue.toString(cron);
    }

    fetchRuleQuantityColumn() {
        return {
            label: "Quantity",
            value: "QUANTITY",
            format: (qty, rule) => {
                return `${qty} ${rule.UNIT_NAME}`;
            },
        };
    }

    fetchRuleTypeColumn() {
        return {label: "Type", value: "TYPE", format: (val) => val.capitalize()};
    }

    fetchRuleScheduleColumn() {
        return {label: "Schedule", value: "CRON", format: (cron) => this.formatCron(cron)};
    }

    fetchRuleLocationsColumn() {
        return {
            label: "Locations",
            value: "LOCATIONS",
            format: (locations, rule) => {
                return rule.LOCATIONS.map((l) => l.NAME).join(", ");
            },
        };
    }

    fetchRulesTableColumns() {
        return [
            this.fetchRuleQuantityColumn(),
            this.fetchRuleTypeColumn(),
            this.fetchRuleScheduleColumn(),
            this.fetchRuleLocationsColumn(),
        ];
    }

    fetchRuleEditButton() {
        return {
            label: "Edit",
            onClick: (rule) => this.ingredientRulesModal.open(rule),
        };
    }

    fetchRulesTableActionButtons() {
        return [this.fetchRuleEditButton()];
    }

    renderRulesTabTable(ingredient) {
        return (
            <Table
                key="rules"
                data={ingredient.RULES}
                columns={this.fetchRulesTableColumns(ingredient)}
                actionButtons={this.fetchRulesTableActionButtons()}
            />
        );
    }

    renderRulesTab(ingredient) {
        return (
            <Card label="Stocking Rules" buttons={this.fetchRulesTabButtons(ingredient)}>
                {this.renderRulesTabTable(ingredient)}
            </Card>
        );
    }

    renderCurrentTab(tab, ingredient) {
        if (tab === "units") {
            return this.renderUnitTab(ingredient);
        } else if (tab === "recipe") {
            return this.renderRecipeTab(ingredient);
        } else if (tab === "pricing") {
            return this.renderPricingTab(ingredient);
        } else if (tab === "production") {
            return this.renderProductionTab(ingredient);
        } else if (tab === "rules") {
            return this.renderRulesTab(ingredient);
        }
    }

    renderBottomTab(ingredient) {
        return (
            <Tab className="ml-2" data={this.fetchTabs(ingredient)}>
                {(tab) => {
                    return this.renderCurrentTab(tab, ingredient);
                }}
            </Tab>
        );
    }

    render() {
        const {ingredients} = this.props.supply;

        const ingredient = ingredients.find(
            (_ingredient) => _ingredient.UNIQUE_ID === this.props.router.params.UNIQUE_ID
        );

        if (!ingredient) {
            return <Loading/>;
        }

        return (
            <>
                {this.includeModals(ingredient)}
                {this.renderPageHeader(ingredient)}
                {this.renderIngredientInformation(ingredient)}
                {this.renderBottomTab(ingredient)}
            </>
        );
    }
}

export default setupReduxConnection(["supply"])(withRouter(RecipePage));
