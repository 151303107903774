import {getURL, request} from "../../request";
import {saveAs} from "file-saver";

export const IngredientRequests = {
    fetchIngredients: async () => {
        return request("recipes/ingredients", "GET");
    },
    fetchIngredient: async (id) => {
        return request("recipes/ingredients/" + id, "GET");
    },
    createIngredient: async (params) => {
        return request("recipes/ingredients", "POST", params);
    },
    updateIngredient: async (id, params) => {
        return request("recipes/ingredients/" + id, "PATCH", params);
    },
    deleteIngredient: async (id) => {
        return request("recipes/ingredients/" + id, "DELETE");
    },
    attachIngredient: async (id, params) => {
        return request("recipes/ingredients/" + id + "/attach", "POST", params);
    },
};

export const UnitRequests = {
    fetchUnits: async () => {
        return request("recipes/units", "GET");
    },
    fetchUnit: async (id) => {
        return request("recipes/units/" + id, "GET");
    },
    updateUnit: async (id, params) => {
        return request("recipes/units/" + id, "PATCH", params);
    },
    createUnit: async (params) => {
        return request("recipes/units/", "POST", params);
    },
    deleteUnit: async (id) => {
        return request("recipes/units/" + id, "DELETE");
    },
};

export const VendorRequests = {
    // TODO: This should be an instanced class, with the VendorId passed in since you're not going to be
    //  making vendor requests for more than one vendor on a page
    fetchVendors: async () => {
        return request("vendors", "GET");
    },
    fetchVendor: async (id) => {
        return request("vendors/" + id, "GET");
    },
    createVendor: async (params) => {
        return request("vendors", "POST", params);
    },
    updateVendor: async (id, params) => {
        return request(`vendors/${id}`, "PATCH", params);
    },
    deleteVendor: async (id, params) => {
        return request(`vendors/${id}`, "DELETE", params);
    },

    fetchVendorContacts: async (vendorId) => {
        return request(`vendor/${vendorId}/contacts`, "GET")
    },
    createVendorContact: async (vendorId, params) => {
        return request(`vendor/${vendorId}/contacts`, "POST", params);
    },
    updateVendorContact: async (vendorId, contactId, params) => {
        return request(`vendor/${vendorId}/contacts/${contactId}`, "PATCH", params);
    },
    deleteVendorContact: async (vendorId, contactId) => {
        return request(`vendor/${vendorId}/contacts/${contactId}`, "DELETE");
    },


    fetchAllVendorItems: async () => {
        return request(`vendors/items`, "GET")
    },

    fetchVendorItems: async (vendorId) => {
        return request(`vendor/${vendorId}/items`, "GET")
    },
    createVendorItem: async (vendorId, params) => {
        return request(`vendor/${vendorId}/items`, "POST", params);
    },
    updateVendorItem: async (vendorId, itemId, params) => {
        return request(`vendor/${vendorId}/items/` + itemId, "PATCH", params);
    },
    deleteVendorItem: async (vendorId, itemId) => {
        return request(`vendor/${vendorId}/items/` + itemId, "DELETE");
    },

    fetchPurchaseOrders: async (vendorId) => {
        return request(`vendors/${vendorId}/purchase_orders/`, "GET");
    }
};

export const PurchaseOrderRequests = {
    fetchPurchaseOrders: async () => {
        return request("purchase/orders/", "GET");
    },
    fetchPurchaseOrder: async (id) => {
        return request("purchase/orders/" + id, "GET");
    },
    createPurchaseOrder: async (params) => {
        return request("purchase/orders", "POST", params);
    },
    updatePurchaseOrder: async (id, params) => {
        return request("purchase/orders/" + id, "PATCH", params);
    },
    deletePurchaseOrder: async (id) => {
        return request("purchase/orders/" + id, "DELETE");
    },
    receivePurchaseOrder: async (id, params) => {
        return request("purchase/orders/receive/" + id, "POST", params);
    },
    sendPurchaseOrder: async (id) => {
        return request("purchase/orders/send/" + id, "POST");
    },
    savePurchaseOrderPDF: async (id) => {
        return saveAs(getURL() + "purchase/orders/" + id + "/pdf", "pdf");
    },
};

export const IngredientCategoryRequests = {
    fetchCategories: async () => {
        return request("recipes/categories", "GET");
    },
    fetchCategory: async (id) => {
        return request("recipes/categories/" + id, "GET");
    },
    createCategory: async (params) => {
        return request("recipes/categories", "POST", params);
    },
};

export const IngredientRulesRequests = {
    createRule: async (params) => {
        return request("recipes/rules", "POST", params);
    },
    updateRule: async (id, params) => {
        return request("recipes/rules/" + id, "PATCH", params);
    },
    deleteRule: async (id) => {
        return request("recipes/rules/" + id, "DELETE");
    },
};

export const IngredientCountRequests = {
    fetchCounts: async () => {
        return request("counts/", "GET");
    },
    fetchCount: async (id) => {
        return request("counts/" + id, "GET");
    },
    updateCount: async (id, params) => {
        return request("counts/" + id, "PATCH", params);
    },
    createCount: async (params) => {
        return request("counts/", "POST", params);
    },
    execute: async (id, params) => {
        return request("counts/" + id + "/execute", "POST", params);
    },
};

export const RecipeRequests = {
    fetchRecipes: async () => {
        return request("recipes/", "GET");
    },
    fetchRecipe: async (id) => {
        return request("recipes/" + id, "GET");
    },
    createRecipe: async (params) => {
        return request("recipes/", "POST", params);
    },
    updateRecipe: async (id, params) => {
        return request("recipes/" + id, "PATCH", params);
    },
    deleteRecipe: async (id) => {
        return request("recipes/" + id, "DELETE");
    },
    attachRecipe: async (productId, params) => {
        return request("recipes/product/" + productId, "POST", params);
    },
    attachIngredient: async (ingredientId, params) => {
        return request("recipes/ingredients/" + ingredientId + "/attach", "POST", params);
    },
};

export const StockRequests = {
    quickStock: async (ingredientId, params) => {
        return request("stocks/quick/" + ingredientId, "POST", params);
    },
    executeRecipe: async (ingredientId, params) => {
        return request("stocks/recipe/" + ingredientId, "POST", params);
    },
};

export const ConversionRequests = {
    createConversion: async (params) => {
        return request("recipes/units/conversions", "POST", params);
    },
    updateConversion: async (id, params) => {
        return request("recipes/units/conversions/" + id, "PATCH", params);
    },
};

export const WasteRequests = {
    fetchWasteTypes: async () => {
        return request("wastes/types", "GET");
    },
    fetchWasteType: async (id) => {
        return request("wastes/types/" + id, "GET");
    },
    createWasteType: async (params) => {
        return request("wastes/types", "POST", params);
    },
    updateWasteType: async (id, params) => {
        return request("wastes/types/" + id, "PATCH", params);
    },
    deleteWasteType: async (id) => {
        return request("wastes/types/" + id, "DELETE");
    }
};

export const SupplyChainUnitRequests = {
    fetchSupplyChainUnitConversions: async () => {
        return request("recipes/units/conversions", "GET");
    },
    fetchSupplyChainUnits: async () => {
        return request("recipes/units", "GET");
    },
    createSupplyChainUnit: async (params) => {
        return request("recipes/units", "POST", params);
    },
    updateSupplyChainUnit: async (id, params) => {
        return request("recipes/units/" + id, "PATCH", params);
    },
    deleteSupplyChainUnit: async (id, params) => {
        return request("recipes/units/" + id, "DELETE", params)
    }
};
