import React, {Component} from "react";
import {Loading, TipInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {
  decimalToDollars,
  parseIdDict,
  randomString,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import {request} from "../../../utils/request";
import ComboBox from "../../../components/combobox";
import {fetchDefaultProductSelections} from "../../../utils/product-helper";
import ProductSelectModal from "../../../modals/sales/product/product-select-modal";
import {
  fetchLineItem,
  updateCartItem,
} from "@frostbyte-technologies/frostbyte-tickets/dist/helpers/cart-helper";
import SelectDiscountModal from "../../../modals/sales/discounts/select-discount-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setupReduxConnection} from "../../../redux";
import InvoiceLineItemsForm from "./invoice-lines-form-components/invoice-line-items-form";
import {cloneDeep} from "lodash";
import * as Yup from "yup";

class InvoiceLinesForm extends Component {
  state = {
    invoiceNumber: 1,
    products: [],
    allDiscounts: [],
    numCustom: 0,
    allServiceFees: [],
  };

  /**
   * Take in the lines, which are the form rows
   * The only editable lines should be custom products, you cannot override prices for menu products
   */
  componentDidMount() {
    const {lines = [], duplicate = null} = this.props;

    const trueLines = duplicate ? duplicate.TICKET.ITEMS : lines;

    Promise.all([
      request("products", "GET"),
      request("partner/discounts/ticket", "GET"),
      request("fees", "GET"),
    ]).then((payload) => {
      const products = payload[0];
      const discounts = payload[1];
      const fees = payload[2];
      trueLines
        .filter((item) => {
          return parseInt(item.OBJECT_ID) < 0;
        })
        .forEach((item) => {
          products.unshift({
            ID: parseInt(item.OBJECT_ID),
            NAME: item.NAME,
            PRICE: item.AMOUNT,
            ONE_TIME: true,
            CUSTOMIZATIONS: [],
            TAX_RATES: [],
          });
        });

      const objectIds = trueLines
        .filter((item) => {
             return parseInt(item.OBJECT_ID) < 0;
        }).map((item) => Math.abs(parseInt(item.OBJECT_ID)));

      const maxCustom = Math.max(...objectIds);
      const numCustom =  !maxCustom ? maxCustom + 1 : 0;

      this.setState({
            products,
            cloneProducts: cloneDeep(products),
            allDiscounts: discounts,
            allServiceFees: fees,
            numCustom,
      });
    });
  }

  submitForm() {
    this.formikRef.submitForm();
  }

  fetchFormData() {
    const {lines, discounts, serviceFees} = this.formikRef.values;

    return {
      lines: lines.map((item) => {
        return {
          ...item,
          AMOUNT: decimalToDollars(item.AMOUNT),
          AMOUNT_TAX: decimalToDollars(item.AMOUNT_TAX),
          QUANTITY: parseInt(item.QUANTITY),
          TOTAL: decimalToDollars(item.AMOUNT) + decimalToDollars(item.AMOUNT_TAX),
          SEQ: item.CART_SEQ,
        };
      }),
      discounts,
      serviceFees,
    };
  }

  async validateForm() {
    const val = await this.formikRef.validateForm();

    if (Object.keys(val).length === 0) {
      await this.formikRef.submitForm();

      return true;
    }

    for (let item of Object.keys(this.formikRef.values)) {
      this.formikRef.setFieldTouched(item, true);
    }

    return false;
  }

  stylizeItem(item) {
    return {
      ...item,
      AMOUNT: toDollars(item.AMOUNT),
      AMOUNT_TAX: toDollars(item.AMOUNT_TAX),
      PRICE: toDollars(item.AMOUNT / (item.QUANTITY || 1)),
      PRICE_TAX: toDollars(item.AMOUNT_TAX / (item.QUANTITY || 1)),
    };
  }

  destylizeItem(item) {
    return {
      ...item,
      AMOUNT: decimalToDollars(item.AMOUNT),
      AMOUNT_TAX: decimalToDollars(item.AMOUNT_TAX),
      PRICE: decimalToDollars(toDollars(decimalToDollars(item.AMOUNT) / item.QUANTITY)),
      PRICE_TAX: decimalToDollars(
        toDollars(decimalToDollars(item.AMOUNT_TAX) / item.QUANTITY)
      ),
    };
  }

  addItemToCart(product, discounts, rates, selections, quantity, items) {
    const destylizedLines = items.map((item) => this.destylizeItem(item));

    const cartItem = fetchLineItem({
      product: product,
      discounts: [],
      rates,
      selections,
      quantity,
    });

    const {items: newItems, discounts: newDiscounts} = updateCartItem({
      items: destylizedLines ?? [],
      cartItem,
      discounts: discounts ?? [],
    });

    return {
      items: newItems.map((item) => this.stylizeItem(item)),
      discounts: newDiscounts,
    };
  }

  updateItemInCart(
    product,
    productDiscounts,
    discounts,
    rates,
    selections,
    quantity,
    item,
    items,
    notes = "",
    merge = false
  ) {
    const destylizedLines = items.map((item) => this.destylizeItem(item));

    const cartItem = fetchLineItem({
      product: {...product, CART_SEQ: item.product?.CART_SEQ},
      discounts: productDiscounts,
      rates,
      selections,
      quantity,
      notes,
    });

    cartItem.ID = item.ID;

    const {items: newItems, discounts: newDiscounts} = updateCartItem({
      items: destylizedLines ?? [],
      cartItem,
      discounts: discounts ?? [],
    });

    return {
      items: merge
        ? this.mergeItems(newItems).map((_item) => this.stylizeItem(_item))
        : newItems.map((_item) => this.stylizeItem(_item)),
      discounts: newDiscounts,
    };
  }

  removeItemFromCart(product, discounts, rates, selections, quantity, item, items) {
    const destylizedLines = items.map((item) => this.destylizeItem(item));

    const {items: newItems, discounts: newDiscounts} = updateCartItem({
      items: destylizedLines ?? [],
      cartItem: item,
      discounts: discounts ?? [],
      removeItem: true,
    });

    return {
      items: newItems.map((item) => this.stylizeItem(item)),
      discounts: newDiscounts,
    };
  }

  formatDiscount(discount) {
    return {
      DISCOUNT_ID: discount.ID,
      IS_TICKET_DISCOUNT: true,
      PERCENT_AMOUNT: discount.TYPE === 1 ? discount.CONTENT : null,
      AMOUNT: discount.TYPE === 0 ? discount.CONTENT : null,
      NAME: discount.NAME,
    };
  }

  addDiscountToCart(discount, items, discounts) {
    const cartDiscount = this.formatDiscount(discount);
    const destylizedLines = items.map((item) => this.destylizeItem(item));
    discounts.push(cartDiscount);

    const {items: newItems, discounts: newDiscounts} = updateCartItem({
      items: destylizedLines ?? [],
      cartItem: null,
      discounts: discounts ?? [],
    });

    return {
      items: this.mergeItems(newItems).map((item) => this.stylizeItem(item)),
      discounts: newDiscounts,
    };
  }

  removeDiscountFromCart(discount, items, discounts) {
    const discountIndex = discounts.findIndex(
      (_discount) => _discount.DISCOUNT_ID === discount.DISCOUNT_ID
    );

    const destylizedLines = items.map((item) => this.destylizeItem(item));
    discounts.splice(discountIndex, 1);

    const {items: newItems, discounts: newDiscounts} = updateCartItem({
      items: destylizedLines,
      cartItem: null,
      discounts: discounts,
    });

    return {
      items: this.mergeItems(newItems).map((item) => this.stylizeItem(item)),
      discounts: newDiscounts,
    };
  }

  updateServiceFees(serviceFees, items, setFieldValue) {
    const newServiceFees = [];
    const {allServiceFees} = this.state;

    const selectedServiceFees = serviceFees.map((fee, index) => {
      const serviceFee = allServiceFees.find((_fee) => _fee.UNIQUE_ID === fee.OBJECT_ID);

      if (!serviceFee) {
        return {
          ID: index,
          UNIQUE_ID: "fee_" + randomString(24),
          NAME: "Custom Fee",
          TYPE: "FLAT",
          AMOUNT: fee.AMOUNT,
          IS_PRE_TAX: true,
        };
      }

      return serviceFee || fee;
    });

    const destylizedItems = items.map((item) => this.destylizeItem(item));

    const subtotal = destylizedItems.reduce((accum, line) => accum + line.AMOUNT, 0);

    const amountTax = destylizedItems.reduce((accum, line) => accum + line.AMOUNT_TAX, 0);

    const total = subtotal + amountTax;

    for (const fee of selectedServiceFees) {
      const base = fee.IS_PRE_TAX ? subtotal : total;

      const feeAmount =
        fee.TYPE === "FLAT" ? fee.AMOUNT : Math.round((fee.AMOUNT / 100) * base);

      const feeTax = fee.RATE ? Math.round(fee.AMOUNT * (fee.RATE / 100)) : 0;

      const cartFee = {
        ID: Math.round(Math.random() * 1000000),
        OBJECT_ID: fee.UNIQUE_ID,
        SELECTIONS: [],
        NAME: fee.NAME,
        TYPE: "FEE",
        LOGO: null,
        QUANTITY: 1,
        AMOUNT_DISCOUNT: 0,
        AMOUNT_REFUNDED: 0,
        AMOUNT_TAX: feeTax,
        AMOUNT_FEES: feeAmount,
        AMOUNT: feeAmount,
        TOTAL: feeAmount + feeTax,
        NOTES: "Service Fee",
      };

      newServiceFees.push(cartFee);
    }

    setFieldValue("serviceFees", newServiceFees);
  }

  addServiceFee(serviceFees, fee, subtotal, total, setFieldValue) {
    if (!fee) {
      return;
    }

    const base = fee.IS_PRE_TAX ? decimalToDollars(subtotal) : decimalToDollars(total);

    const feeAmount =
      fee.TYPE === "FLAT" ? fee.AMOUNT : Math.round((fee.AMOUNT / 100) * base);

    const feeTax = fee.RATE ? Math.round(feeAmount * (fee.RATE / 100)) : 0;

    const cartFee = {
      ID: Math.round(Math.random() * 1000000),
      OBJECT_ID: fee.UNIQUE_ID,
      SELECTIONS: [],
      NAME: fee.NAME,
      TYPE: "FEE",
      LOGO: null,
      QUANTITY: 1,
      AMOUNT_DISCOUNT: 0,
      AMOUNT_REFUNDED: 0,
      AMOUNT_TAX: feeTax,
      AMOUNT_FEES: feeAmount,
      AMOUNT: feeAmount,
      TOTAL: feeAmount + feeTax,
      NOTES: "Service Fee",
    };

    serviceFees.push(cartFee);
    setFieldValue("serviceFees", serviceFees);
  }

  removeServiceFee(serviceFees, fee, setFieldValue) {
    const serviceFeeIndex = serviceFees.findIndex((_fee) => _fee.ID === fee.ID);
    serviceFees.splice(serviceFeeIndex, 1);
    setFieldValue("serviceFees", serviceFees);
  }

  renderDiscounts(linesValue, discountsValue, setFieldValue) {
    return discountsValue.map((_discount) => (
      <div className={"py-2 px-4 border-b "}>
        <div className={"flex flex-row justify-between"}>
          <div>
            <div className={"font-semibold text-sm"}>{_discount.NAME}</div>

            <div className={"text-sm"}>
              {_discount.PERCENT_AMOUNT === null || _discount.PERCENT_AMOUNT === undefined
                ? toDollars(_discount.AMOUNT, true)
                : _discount.PERCENT_AMOUNT + "%"}
            </div>
          </div>

          <div className={"flex flex-row"}>
            <div
              onClick={() => {
                const {items, discounts: newDiscounts} = this.removeDiscountFromCart(
                  _discount,
                  linesValue,
                  discountsValue
                );

                setFieldValue("lines", items);
                setFieldValue("discounts", newDiscounts);
              }}
              className="flex flex-col justify-center text-md text-red-500 font-bold  hover:text-red-700 hover:cursor-pointer transition-colors"
            >
              <FontAwesomeIcon icon="fa-solid fa-x" />
            </div>
          </div>
        </div>
      </div>
    ));
  }

  renderServiceFees(serviceFeesValue, setFieldValue) {
    return serviceFeesValue.map((fee) => (
      <div className={"py-2 px-4 border-b "}>
        <div className={"flex flex-row justify-between"}>
          <div>
            <div className={"font-semibold text-sm"}>{fee.NAME}</div>

            <div className={"text-sm"}>{toDollars(fee.AMOUNT, true)}</div>
          </div>

          <div className={"flex flex-row"}>
            <div
              onClick={() => {
                this.removeServiceFee(serviceFeesValue, fee, setFieldValue);
              }}
              className="flex flex-col justify-center text-md text-red-500 font-bold  hover:text-red-700 hover:cursor-pointer transition-colors"
            >
              <FontAwesomeIcon icon="fa-solid fa-x" />
            </div>
          </div>
        </div>
      </div>
    ));
  }

  compareItems(item1, item2) {
    if (item1.product?.CART_SEQ !== item2.product?.CART_SEQ) {
      return false;
    }

    if (item1.OBJECT_ID !== item2.OBJECT_ID) {
      return false;
    }

    const {SELECTIONS: item1Selections} = item1;
    const {SELECTIONS: item2Selections} = item2;

    if (item1Selections.length !== item2Selections.length) {
      return false;
    }

    const item2SelectionsDict = parseIdDict(item2Selections, "OPTION_ID");

    for (const item1Selection of item1Selections) {
      const item2Selection = item2SelectionsDict[item1Selection.OPTION_ID];

      if (!item2Selection) {
        return false;
      }

      if (item1Selection.AMOUNT !== item2Selection.AMOUNT) {
        return false;
      }
    }

    return true;
  }

  executeMerge(items, item1, item2, i, j) {
    const toKeep = item1.QUANTITY > item2.QUANTITY ? item1 : item2;
    const toSplice = item1.QUANTITY < item2.QUANTITY ? item1 : item2;
    const toSpliceIndex = item1.QUANTITY > item2.QUANTITY ? j : i;

    items.splice(toSpliceIndex, 1);

    toKeep.QUANTITY = parseInt(toSplice.QUANTITY) + parseInt(toKeep.QUANTITY);
  }

  mergeItems(items) {
    for (let i = items.length - 1; i > 0; i--) {
      for (let j = i - 1; j >= 0; j--) {
        if (this.compareItems(items[j], items[i])) {
          this.executeMerge(items, items[j], items[i], j, i);
          break;
        }
      }
    }

    return items;
  }

  render() {
    const {handleSubmit, lines, discounts, serviceFees, actionTextClassName, duplicate} =
      this.props;

    const {products, allDiscounts, allServiceFees, cloneProducts, tipType} = this.state;

    if (products.length === 0) {
      return <Loading />;
    }

    const initialValues = {
      lines:
        lines?.map((item) => {
          return {
            ...item,
            OBJECT_ID: item.OBJECT_ID ? parseInt(item.OBJECT_ID) : null,
            AMOUNT: toDollars(item.AMOUNT),
            AMOUNT_TAX: toDollars(item.AMOUNT_TAX),
            PRICE: toDollars(item.AMOUNT / item.QUANTITY),
            PRICE_TAX: toDollars(item.AMOUNT_TAX / item.QUANTITY),
            SELECTIONS: item.SELECTIONS?.map((selection) => ({
              ...selection,
              TAX_RATES: selection.TAX_RATES ?? [],
            })),
            TAX_RATES: item.TAX_RATES,
            QUANTITY: "" + item.QUANTITY,
          };
        }) || [],
      discounts: discounts || [],
      serviceFees: serviceFees || [],
      tips: 0,
      tipType: tipType,
    };

    if (duplicate) {
      initialValues.lines = (duplicate.TICKET.ITEMS || [])
        .sort((a, b) => a.SEQ - b.SEQ)
        .map((item) => {
          return {
            ...item,
            OBJECT_ID: item.OBJECT_ID ? parseInt(item.OBJECT_ID) : null,
            AMOUNT: toDollars(item.AMOUNT),
            AMOUNT_TAX: toDollars(item.AMOUNT_TAX),
            PRICE: toDollars(item.AMOUNT / item.QUANTITY),
            PRICE_TAX: toDollars(item.AMOUNT_TAX / item.QUANTITY),
            SELECTIONS: item.SELECTIONS.map((selection) => ({
              ...selection,
              TAX_RATES: selection.TAX_RATES ?? [],
            })),
            TAX_RATES: item.TAX_RATES,
            QUANTITY: "" + item.QUANTITY,
            AMOUNT_REFUNDED: 0,
            DATE_REFUNDED: null,
            DATE_PAYED: null,
            DATE_STARTED: null,
            DATE_COMPLETED: null,
            product: {CART_SEQ: item.SEQ},
          };
        });
    }

    const validationSchema = Yup.object({
      tips: Yup.number()
        .nullable()
        .test(function (value) {
          const { lines } = this.parent;

          if (!lines || lines.length === 0) return true;

          const tipTotal = lines
            .filter((line) => line.TYPE === 'TIP')
            .reduce((sum, line) => sum + Number(line.AMOUNT || 0), 0);

          const nonTipTotal = lines
            .filter((line) => line.TYPE !== 'TIP')
            .reduce((sum, line) => sum + Number(line.AMOUNT || 0), 0);

          if (tipTotal > nonTipTotal) {
            return this.createError({
              path: 'tips',
              message: 'Tip cannot exceed subtotal.',
            });
          }

          return true;
        }),
    });

    return (
      <div>
        <Formik
          onSubmit={handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {(formikOptions) => {
            const {values, handleSubmit, setFieldValue, errors, setFieldError} = formikOptions;

            const linesValue = values.lines;
            const discountsValue = values.discounts;
            const serviceFeesValue = values.serviceFees;

            const subtotal = toDollars(
              linesValue
                .filter((line) => line.TYPE !== "TIP")
                .reduce(
                (accum, line) => accum + decimalToDollars(line.AMOUNT),
                0
              ),
              true
            );

            const amount = toDollars(
              linesValue.reduce(
                (accum, line) => accum + decimalToDollars(line.AMOUNT),
                0
              ),
              true
            );

            const amountTax = linesValue.reduce(
              (accum, line) => accum + decimalToDollars(line.AMOUNT_TAX),
              0
            );

            const serviceFeeTax = serviceFeesValue.reduce(
              (accum, line) => accum + line.AMOUNT_TAX,
              0
            );

            const total = toDollars(decimalToDollars(amount) + amountTax, true);

            const pushTipLine = (values) => {
              const {lines = [], tipAmount} = values;

              const nonTipLines = lines.filter((line) => line.TYPE !== "TIP");

              if (tipAmount > 0) {
                nonTipLines.push({
                  OBJECT_ID: randomString(8),
                  NAME: "Tip",
                  AMOUNT: toDollars(tipAmount),
                  AMOUNT_TAX: 0,
                  QUANTITY: 1,
                  TOTAL: toDollars(tipAmount),
                  TYPE: "TIP",
                });
              }

              return nonTipLines.map((item) => {
                return {
                  ...item,
                  AMOUNT: item.AMOUNT,
                  AMOUNT_TAX: item.AMOUNT_TAX,
                  QUANTITY: item.QUANTITY,
                  TOTAL: item.AMOUNT + item.AMOUNT_TAX,
                  SEQ: item.CART_SEQ,
                };
              });
            };

            const calculateTips = (values) => {
              const {lines, serviceFees, tips = 0, tipType = "dollar"} = values;

              const nonTipLines = lines.filter((line) => line.TYPE !== "TIP");

              const subtotalNoTax = nonTipLines.reduce(
                (acc, item) => acc + decimalToDollars(item.AMOUNT),
                0
              );
              const totalTax = nonTipLines.reduce(
                (acc, item) => acc + decimalToDollars(item.AMOUNT_TAX),
                0
              );
              const serviceFeeTax = serviceFees.reduce(
                (acc, fee) => acc + decimalToDollars(fee.AMOUNT_TAX),
                0
              );

              const subtotal = subtotalNoTax + totalTax + serviceFeeTax;

              const tipAmount =
                tipType === "percentage"
                  ? subtotal * (tips / 100)
                  : decimalToDollars(tips);

              return tipAmount;
            }

            const handleTipChange = (newTip, newTipType) => {
              const updatedValues = {
                ...values,
                tips: newTip,
                tipType: newTipType,
              };

              const tipAmount = calculateTips(updatedValues);

              const tipLines = pushTipLine({ ...values, tipAmount });

              setFieldValue("tips", newTip);
              setFieldValue("tipType", newTipType);
              setFieldValue("lines", tipLines);
            };

            return (
              <form onSubmit={handleSubmit}>
                <ProductSelectModal
                  ref={(e) => (this.productSelectModal = e)}
                  items={[...products, ...serviceFeesValue]}
                  discounts={discountsValue}
                />

                <SelectDiscountModal ref={(e) => (this.selectDiscountModal = e)} />

                <InvoiceLineItemsForm
                  options={formikOptions}
                  products={cloneProducts}
                  onAddLineItem={() => {
                    const {numCustom} = this.state;

                    const product = {
                      LOGO: "appicon.png",
                      TYPE: "CUSTOM_PRODUCT",
                      PRICE: 0,
                      PRICE_TAX: 0,
                      QUANTITY: 1,
                      TAX_RATES: [],
                      SELECTIONS: [],
                      AMOUNT: 0,
                      AMOUNT_TAX: 0,
                      NAME: null,
                      ID: "" + -(numCustom + 1),
                      CART_SEQ: linesValue.length + 1,
                    };

                    const {items, discounts: newTicketDiscounts} = this.addItemToCart(
                      product,
                      discountsValue,
                      [],
                      [],
                      1,
                      linesValue
                    );

                    this.updateServiceFees(serviceFeesValue, items, setFieldValue);

                    setFieldValue("lines", items);
                    setFieldValue("discounts", newTicketDiscounts);
                    this.setState({numCustom: numCustom + 1});
                  }}
                  onCreateLineItem={(query, line) => {
                    const {numCustom} = this.state;
                    const {taxRates} = this.props.shop;

                    const defaultTaxRates = taxRates.filter((rate) => rate.IS_DEFAULT);

                    const product = {
                      ID: -(numCustom + 1),
                      NAME: query,
                      PRICE: 0,
                      ONE_TIME: true,
                      CUSTOMIZATIONS: [],
                      TAX_RATES: defaultTaxRates,
                    };

                    products.unshift(product);

                    const {items, discounts: newTicketDiscounts} = this.updateItemInCart(
                      product,
                      [],
                      discountsValue,
                      defaultTaxRates,
                      [],
                      1,
                      line,
                      linesValue
                    );

                    this.updateServiceFees(serviceFeesValue, items, setFieldValue);

                    this.setState({
                      products,
                      cloneProducts: cloneDeep(products),
                      numCustom: numCustom + 1,
                    });

                    setFieldValue("lines", items);
                    setFieldValue("discounts", newTicketDiscounts);
                  }}
                  onChangeLineItem={(item, line) => {
                    if (!item || line.OBJECT_ID === item.item.ID) return;

                    const {items, discounts: newTicketDiscounts} = this.updateItemInCart(
                      item.item,
                      [],
                      discountsValue,
                      item.item.TAX_RATES,
                      fetchDefaultProductSelections(item.item),
                      1,
                      line,
                      linesValue ?? []
                    );

                    this.updateServiceFees(serviceFeesValue, items, setFieldValue);

                    setFieldValue("lines", items);
                    setFieldValue("discounts", newTicketDiscounts);
                  }}
                  onCustomize={async (line) => {
                    const product = products.find(
                      (_product) => _product.ID === line.OBJECT_ID
                    );

                    try {
                      const {selections, rates, quantity, notes, discounts} =
                        await this.productSelectModal.open(
                          cloneDeep(product),
                          cloneDeep(line)
                        );

                      const {items, discounts: newTicketDiscounts} =
                        this.updateItemInCart(
                          product,
                          discounts,
                          discountsValue,
                          rates,
                          selections,
                          quantity,
                          line,
                          linesValue,
                          notes
                        );

                      this.updateServiceFees(serviceFeesValue, items, setFieldValue);

                      setFieldValue("lines", items);
                      setFieldValue("discounts", newTicketDiscounts);
                    } catch (e) {}
                  }}
                  onChangeQuantity={(e, index) => {
                    const val = e.target.value || 0;

                    if (parseInt(val + "") === 0) {
                      return;
                    }

                    const lineItem = linesValue[index];

                    const product = products.find(
                      (product) => product.ID === lineItem.OBJECT_ID
                    );

                    lineItem.QUANTITY = val;

                    const {items, discounts} = this.updateItemInCart(
                      product,
                      lineItem.DISCOUNTS,
                      discountsValue,
                      lineItem.TAX_RATES,
                      lineItem.SELECTIONS,
                      val,
                      lineItem,
                      linesValue,
                      lineItem.NOTES,
                      true
                    );

                    this.updateServiceFees(serviceFeesValue, items, setFieldValue);

                    setFieldValue("lines", items);
                    setFieldValue("discounts", discounts);
                  }}
                  onChangePrice={(index, val) => {
                    val = decimalToDollars(val);

                    const lineItem = linesValue[index];
                    const {taxRates: allTaxRates} = this.props.shop;

                    this.setState((prevState) => {
                      const {products: prevProducts} = prevState;

                      const product = prevProducts.find(
                        (item) => item.ID === lineItem.OBJECT_ID
                      );

                      if (!product) return;

                      if (product.ONE_TIME) {
                        product.PRICE = val;
                      } else {
                        lineItem.FORCE_RESET = !lineItem.FORCE_RESET;
                        setFieldValue("lines", linesValue);

                        return;
                      }

                      const taxRates = allTaxRates.filter(
                        (taxRate) =>
                          !!lineItem.TAX_RATES.find(
                            (_taxRate) => _taxRate.TAX_ID === taxRate.ID
                          )
                      );

                      const {items, discounts} = this.updateItemInCart(
                        product,
                        [],
                        discountsValue,
                        taxRates,
                        [],
                        lineItem.QUANTITY ?? 1,
                        lineItem,
                        linesValue,
                        "",
                        false
                      );

                      this.updateServiceFees(serviceFeesValue, items, setFieldValue);

                      setFieldValue("lines", items);
                      setFieldValue("discounts", discounts);

                      return {products};
                    });
                  }}
                  onDeleteLineItem={(index) => {
                    const lineItem = linesValue[index];

                    const product = products.find(
                      (product) => product.ID === lineItem.OBJECT_ID
                    );

                    const {items, discounts: newDiscounts} = this.removeItemFromCart(
                      product,
                      discountsValue,
                      lineItem.TAX_RATES,
                      lineItem.SELECTIONS,
                      lineItem.QUANTITY,
                      lineItem,
                      linesValue
                    );

                    this.updateServiceFees(serviceFeesValue, items, setFieldValue);

                    setFieldValue("discounts", newDiscounts);
                    setFieldValue("lines", items);
                  }}
                />

                {errors.lines && (
                  <div className={"text-sm text-red-700 py-2 px-4 border-b"}>
                    Error: line item names are required.
                  </div>
                )}

                <div className={"flex flex-row justify-end"}>
                  <div
                    className={"flex flex-col justify-end w-full border-b  rounded-b-sm"}
                  >
                    {this.renderDiscounts(linesValue, discountsValue, setFieldValue)}

                    <ComboBox
                      onCreate={(query) => {
                        const parsedQuery = query.replace("$", "").replace("%", "");

                        const isValid =
                          query && query.length > 0 && !isNaN(parseInt(parsedQuery));

                        if (!isValid) {
                          return;
                        }

                        const isPercent = query.includes("%");

                        const discount = {
                          DISCOUNT_ID: null,
                          IS_TICKET_DISCOUNT: true,
                          CONTENT: isPercent
                            ? parseInt(parsedQuery)
                            : decimalToDollars(parsedQuery),
                          TYPE: isPercent ? 1 : 0,
                          NAME: "Custom Discount",
                        };

                        const {items, discounts: newDiscounts} = this.addDiscountToCart(
                          discount,
                          linesValue,
                          discountsValue
                        );

                        this.updateServiceFees(serviceFeesValue, items, setFieldValue);

                        setFieldValue("lines", items);
                        setFieldValue("discounts", newDiscounts);
                      }}
                      createLabel="Enter a percentage or dollar amount"
                      onChange={(index, discount) => {
                        if (discount.id === null) {
                          return;
                        }

                        const {items, discounts: newDiscounts} = this.addDiscountToCart(
                          discount.discount,
                          linesValue,
                          discountsValue
                        );

                        this.updateServiceFees(serviceFeesValue, items, setFieldValue);

                        setFieldValue("lines", items);
                        setFieldValue("discounts", newDiscounts);
                      }}
                      ignoreMargin
                      placeholder="Add discount"
                      compact
                      data={[
                        {label: "No Discounts", id: null},
                        ...allDiscounts.map((discount) => {
                          return {
                            label: discount.NAME,
                            id: discount.ID,
                            discount,
                          };
                        }),
                      ]}
                    />

                    <div className={"flex flex-col text-sm border-t border-b p-4"}>
                      <div className={"flex flex-row justify-between mb-2"}>
                        <div className={"font-bold"}>Subtotal</div>

                        <div className={"font-bold"}>{subtotal}</div>
                      </div>

                      <div className={"flex flex-row justify-between"}>
                        <div>Tax</div>{" "}
                        <div>{toDollars(amountTax + serviceFeeTax, true)}</div>
                      </div>

                      <div className={"flex flex-row justify-between mb-2"}>
                        <div>Tip</div>{" "}
                        <div>
                          <TipInput
                            initialTip={values.tips}
                            initialTipType={values.tipType}
                            onTipChange={handleTipChange}
                          />
                          {errors.tips && (
                            <div className="text-red-500 text-sm mt-1">
                              {errors.tips}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {this.renderServiceFees(serviceFeesValue, setFieldValue)}

                    <ComboBox
                      onChange={(index, fee) => {
                        this.addServiceFee(
                          serviceFeesValue,
                          fee.fee,
                          amount,
                          total,
                          setFieldValue
                        );
                      }}
                      onCreate={(query) => {
                        const parsedQuery = query.replace("$", "").replace("%", "");

                        const isValid =
                          query && query.length > 0 && !isNaN(parseInt(parsedQuery));

                        if (!isValid) {
                          return;
                        }

                        const isPercent = query.includes("%");

                        const fee = {
                          ID: -serviceFeesValue.length,
                          UNIQUE_ID: "fee_" + randomString(24),
                          NAME: "Custom Fee",
                          TYPE: isPercent ? "PERCENT" : "FLAT",
                          AMOUNT: isPercent ? parsedQuery : decimalToDollars(parsedQuery),
                          IS_PRE_TAX: true,
                        };

                        this.addServiceFee(
                          serviceFeesValue,
                          fee,
                          amount,
                          total,
                          setFieldValue
                        );

                        this.setState((prevState) => {
                          const {allServiceFees: prevServiceFees} = prevState;
                          return {allServiceFees: [...prevServiceFees, fee]};
                        });
                      }}
                      createLabel={"Enter a dollar amount for the service fee."}
                      ignoreMargin
                      placeholder="Add service fee"
                      compact
                      data={[
                        {label: "No Fee", id: null},
                        ...allServiceFees.map((fee) => {
                          return {
                            label: fee.NAME,
                            id: fee.ID,
                            fee,
                          };
                        }),
                      ]}
                    />

                    <div
                      className={"flex flex-row bg-gray-200 text-sm justify-between p-3"}
                    >
                      <div>Total</div>{" "}
                      <div>
                        {toDollars(
                          decimalToDollars(total) +
                            serviceFeesValue.reduce((accum, fee) => accum + fee.TOTAL, 0),
                          true
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

InvoiceLinesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  lines: PropTypes.array.isRequired,
};

export default setupReduxConnection(["shop"])(InvoiceLinesForm);
