import {request} from "../utils/request";
import {VendorRequests} from "../utils/request-helpers/supply-chain/supply-chain-requests";

const defaultState = {
    brandedApps: null,
};

const UPDATE_BRANDED_APPS = "UPDATE_BRANDED_APPS";

export function updateBrandedApps(payload) {
    return {type: UPDATE_BRANDED_APPS, payload};
}

export function loadBrandedApps() {
    return async (dispatch, getState) => {
        const brandedApps = await request("branded");

        dispatch(updateBrandedApps(brandedApps));
    };
}

export const adminReducer = (state = defaultState, action) => {
    let {type, payload} = action;

    switch (type) {
        default:
            return state;
        case UPDATE_BRANDED_APPS:
            return {...state, brandedApps: payload};
    }
};
