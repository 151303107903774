import React, {Component} from "react";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import IngredientsTable from "../../../../tables/operations/recipes/supply-chain-inventory-table";
import IngredientModal from "../../../../modals/operations/supply-chain/recipes/ingredient-modal";
import RecipeBuilder from "../../../../features/operations/supply-chain/recipe-builder/recipe-builder";
import {getStore, setupReduxConnection} from "../../../../redux";
import SupplyComingSoonPage from "../supply-coming-soon-page";
import IngredientCategoryModal from "../../../../modals/operations/supply-chain/recipes/ingredient-category-modal";
import {withRouter} from "../../../../utils/navigation";
import {updateIngredients} from "../../../../redux/supply";
import MigrateMenuModal from "../../../../modals/operations/supply-chain/recipes/setup-product-ingredients/migrate-menu/migrate-menu-modal";
import LoadingSpinner from "../../../../components/loading-spinner";
import {isSupplyChainWhitelisted} from "../../../../utils/util";
import QuickStockIngredientModal from "../../../../modals/operations/supply-chain/recipes/quick-stock-ingredient-modal";

const VIEW_TYPES = {TABLE: "TABLE", TREE: "TREE"};

class SupplyChainInventoryPage extends Component {
  state = {view: VIEW_TYPES.TABLE};
  intervalToCancel = {}

  componentDidMount() {
    this.intervalToCancel = setInterval(() => {
      getStore().dispatch(updateIngredients());
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalToCancel);
  }

  includeModals() {
    return (
      <>
        <IngredientModal ref={(e) => (this.ingredientModal = e)} />
        <IngredientCategoryModal ref={(e) => (this.ingredientCategoryModal = e)} />
        <MigrateMenuModal ref={(e) => (this.migrateMenuModal = e)} />
        <QuickStockIngredientModal ref={(e) => (this.quickStockIngredientModal = e)} />
      </>
    );
  }

  fetchCreateIngredientButton() {
    return {
      label: "Create Item or Ingredient",
      onClick: () => this.ingredientModal.open(),
    };
  }

  fetchCreateIngredientGroupButton() {
    return {
      label: "Create Ingredient Group",
      onClick: () => this.ingredientCategoryModal.open(),
    };
  }

  fetchMigrateMenuButton() {
    return {
      label: "Migrate Menu to Ingredients",
      onClick: () => this.migrateMenuModal.open(),
    };
  }

  fetchMoreButtons() {
    return {
      type: "dropdown",
      label: "More",
      sections: [
        {
          items: [
            this.fetchCreateIngredientButton(),
            this.fetchCreateIngredientGroupButton(),
            this.fetchMigrateMenuButton(),
          ],
        },
      ],
    };
  }

  fetchTableViewButton() {
    return {
      label: "Table",
      onClick: () => this.setState({view: VIEW_TYPES.TABLE}),
    };
  }

  fetchTreeViewButton() {
    return {
      label: "Tree",
      onClick: () => this.setState({view: VIEW_TYPES.TREE}),
    };
  }

  fetchPageHeadingButtons() {
    return [
      this.fetchCreateIngredientButton(),
      this.fetchMoreButtons(),
    ];
  }

  renderPageHeadings() {
    return (
      <PageHeadings
        label="Items and Ingredients"
        description="Create and view items and ingredients."
        buttons={this.fetchPageHeadingButtons()}
      />
    );
  }

  fetchEditButton() {
    return {
      label: "Edit",
      onClick: (_ingredient) => this.ingredientModal.open(_ingredient),
    };
  }

  fetchViewButton() {
    return {
      label: "View",
      onClick: (row) =>
        this.props.router.navigate("/recipes/ingredients/" + row.UNIQUE_ID),
    };
  }

  fetchQuickStockButton() {
    return {
      label: "Quick Stock",
      onClick: (ingredient) => {
        return this.quickStockIngredientModal.open(ingredient);
      },
    };
  }

  fetchIngredientsTableActionButtons() {
    return [this.fetchQuickStockButton(), this.fetchEditButton(), this.fetchViewButton()];
  }

  renderTableView() {
    const {ingredients} = this.props.supply;

    return (
      <IngredientsTable
        data={ingredients}
        actionButtons={this.fetchIngredientsTableActionButtons()}
      />
    );
  }

  renderTreeView() {
    const {ingredients} = this.props.supply;
    return <RecipeBuilder ingredients={ingredients} />;
  }

  renderView() {
    const {view} = this.state;

    if (view === VIEW_TYPES.TABLE) {
      return this.renderTableView();
    }

    return this.renderTreeView();
  }

  render() {
    const {ingredients, categories} = this.props.supply;

    if (!ingredients || !categories) {
      return <LoadingSpinner />;
    }

    return (
      <>
        {this.includeModals()}
        {this.renderPageHeadings()}
        {this.renderView()}
      </>
    );
  }
}

export default setupReduxConnection(["shop", "supply"])(withRouter(SupplyChainInventoryPage));
