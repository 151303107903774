import moment from "moment";
import DangerBadge from "../../../components/badges/danger-badge";
import SuccessBadge from "../../../components/badges/success-badge";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import React from "react";
import {formatNumberCommas} from "../../../utils/util";
import WarningBadge from "../../../components/badges/warning-badge";

export const TIMEFRAMES = {
    SUMMARY: "TIMESPAN",
    DAILY: "DAY",
    WEEKLY: "WEEK",
    MONTHLY: "MONTH",
};

export const CELL_TYPES = {
    BOLD: "bold",
    REGULAR: "regular",
    SECONDARY: "secondary",
    NEGATIVE: "negative",
    FLOAT_LEFT: "float-left",
};

export const SALES_SUMMARY_ROWS = [
    {
        label: "Gross Sales",
        selector: "GROSS_SALES",
        rowType: CELL_TYPES.BOLD,
        dollarAmount: true,
        style: [CELL_TYPES.BOLD],
    },
    {
        label: "Product Sales",
        selector: "PRODUCT_SALES",
        dollarAmount: true,
        secondary: true,
        style: [CELL_TYPES.SECONDARY],
    },
    {
        label: "Auxiliary Sales",
        selector: "OTHER_SALES",
        rowType: CELL_TYPES.SECONDARY,
        dollarAmount: true,
        secondary: true,
        style: [CELL_TYPES.SECONDARY],
    },
    {
        label: "Discounts",
        selector: "DISCOUNTS",
        rowType: CELL_TYPES.NEGATIVE,
        dollarAmount: true,
        negative: true,
        style: [CELL_TYPES.NEGATIVE],
    },
    {
        label: "Net Sales",
        selector: "NET_SALES",
        rowType: CELL_TYPES.BOLD,
        dollarAmount: true,
        bold: true,
        style: [CELL_TYPES.BOLD],
    },
    {
        label: "Tips",
        selector: "TIPS",
        rowType: CELL_TYPES.REGULAR,
        dollarAmount: true,
        style: [CELL_TYPES.REGULAR],
    },
    {
        label: "Card Tips",
        selector: "CARD_TIPS",
        rowType: CELL_TYPES.SECONDARY,
        dollarAmount: true,
        style: [CELL_TYPES.SECONDARY],
    },
    {
        label: "Cash Tips",
        selector: "CASH_TIPS",
        rowType: CELL_TYPES.SECONDARY,
        dollarAmount: true,
        style: [CELL_TYPES.SECONDARY],
    },
    {
        label: "Collected Fees",
        selector: "FEES_COLLECTED",
        rowType: CELL_TYPES.REGULAR,
        dollarAmount: true,
        style: [CELL_TYPES.REGULAR],
    },
    {
        label: "Tax",
        selector: "TAXES",
        rowType: CELL_TYPES.REGULAR,
        dollarAmount: true,
        style: [CELL_TYPES.REGULAR],
    },
    {
        label: "Total Collected",
        selector: "TOTAL_COLLECTED",
        type: CELL_TYPES.BOLD,
        dollarAmount: true,
        bold: true,
        style: [CELL_TYPES.BOLD],
    },
    {
        label: "Refunds",
        selector: "REFUNDS",
        rowType: CELL_TYPES.NEGATIVE,
        dollarAmount: true,
        negative: true,
        style: [CELL_TYPES.NEGATIVE],
    },
    {
        label: "Processing",
        selector: "PROCESSING_FEES",
        rowType: CELL_TYPES.NEGATIVE,
        dollarAmount: true,
        negative: true,
        style: [CELL_TYPES.NEGATIVE],
    },
    {
        label: "Net Total",
        selector: "NET_TOTAL",
        rowType: CELL_TYPES.BOLD,
        dollarAmount: true,
        bold: true,
        style: [CELL_TYPES.BOLD],
    },
];

export const PAYMENT_METHODS_COLUMNS = [
    {
        label: "Total Collected",
        columnSelector: "TOTAL_COLLECTED",
        columnType: CELL_TYPES.REGULAR,
        dollarAmount: true,
        style: [],
        tooltip:
            "This value represents the total amount of sales collected after taking taxes, collected fees, tips, and discounts into account.",
    },
    {
        label: "(Refund Amount)",
        columnSelector: "REFUND_AMOUNT",
        columnType: CELL_TYPES.NEGATIVE,
        dollarAmount: true,
        style: [CELL_TYPES.NEGATIVE],
        tooltip:
            "This value represents the total amount that was refunded from sales made during your selected time period.",
    },
    {
        label: "Net Payment",
        columnSelector: "NET_PAYMENT",
        columnType: CELL_TYPES.REGULAR,
        dollarAmount: true,
        style: [],
        tooltip:
            "This value represents the total amount of sales collected after taking refunds into account.",
    },
    {
        label: "(Processing)",
        columnSelector: "PROCESSING_FEES",
        columnType: CELL_TYPES.NEGATIVE,
        dollarAmount: true,
        style: [CELL_TYPES.NEGATIVE],
        tooltip: "This value represents the total amount of fees collected from processing.",
    },
    {
        label: "Net Total",
        columnSelector: "NET_TOTAL",
        columnType: CELL_TYPES.BOLD,
        dollarAmount: true,
        style: [CELL_TYPES.BOLD],
        tooltip:
            "This value represents the total amount of sales collected after taking processing fees into account.",
    },
];

export const PRODUCT_SALES_COLUMNS = [
    {label: "Category", columnSelector: "CATEGORY_NAME"},
    {
        label: "Products Sold",
        columnSelector: "ORDER_COUNT",
        tooltip:
            "The value under this column represents the total amount of times that product was sold during your selected time period.",
    },
    {
        label: "Gross Sales",
        columnSelector: "GROSS_SALES",
        dollarAmount: true,
        columnType: CELL_TYPES.BOLD,
        style: [CELL_TYPES.BOLD],
        tooltip:
            "The value under this column represents the total amount of sales collected from that product, without accounting for taxes, refunds, or discounts.",
    },
    {
        label: "Estimated Cost",
        columnSelector: "ESTIMATED_COST",
        dollarAmount: true,
        columnType: CELL_TYPES.BOLD,
        style: [CELL_TYPES.BOLD],
        tooltip: "This represents the estimated cost of this product. This is based on the entered cost of the product during product pricing."
    },
    {
        label: "(Item Discounts)",
        columnSelector: "DISCOUNTS",
        dollarAmount: true,
        columnType: CELL_TYPES.NEGATIVE,
        style: [CELL_TYPES.NEGATIVE],
        tooltip:
            "The value under this column represents the total amount that was discounted from sales of that specific product during your selected time period.",
    },
    {
        label: "Net Sales",
        columnSelector: "NET_SALES",
        dollarAmount: true,
        columnType: CELL_TYPES.BOLD,
        style: [CELL_TYPES.BOLD],
        tooltip:
            "The value under this column represents the total amount of sales collected from that product, after taking discounts into account.",
    },
    {
        label: "Tax",
        columnSelector: "TAXES",
        dollarAmount: true,
        style: [],
        tooltip:
            "The value under this column represents the total amount of taxable sales collected from purchases of the product.",
    },
    {
        label: "Total Collected",
        columnSelector: "TOTAL_COLLECTED",
        dollarAmount: true,
        style: [],
        tooltip:
            "The value under this column represents the total amount of sales collected from that product, after taking taxes into account.",
    },
    {
        label: "Refunds",
        columnSelector: "REFUNDS",
        dollarAmount: true,
        style: [],
        tooltip:
            "The value under this column represents the total amount of refunds given for the product during your selected time period.",
    },
    {
        label: "Net Payment",
        columnSelector: "NET_TOTAL",
        dollarAmount: true,
        columnType: CELL_TYPES.BOLD,
        style: [CELL_TYPES.BOLD],
        tooltip:
            "The value under this column represents the total amount of sales collected from that product, after taking refunds into account.",
    },
];

export const CATEGORY_SALES_COLUMNS = [
    {
        label: "Quantity Sold",
        columnSelector: "ORDER_COUNT",
        tooltip:
            "The value under this column represents the total number of times that a product from that category was sold during your selected time period.",
    },
    {
        label: "Gross Sales",
        columnSelector: "GROSS_SALES",
        dollarAmount: true,
        style: [CELL_TYPES.BOLD],
        tooltip:
            "The value under this column represents the total amount of sales collected from products under that category, without accounting for taxes, refunds, or discounts.",
    },
    {
        label: "(Refunds)",
        columnSelector: "REFUNDS",
        dollarAmount: true,
        style: [CELL_TYPES.NEGATIVE],
        tooltip:
            "The value under this column represents the total amount that was refunded from sales of products under that category during your selected time period.",
    },
    {
        label: "(Item Discounts)",
        columnSelector: "DISCOUNTS",
        dollarAmount: true,
        style: [CELL_TYPES.NEGATIVE],
        tooltip:
            "The value under this column represents the total amount that was discounted from sales of products under that category during your selected time period.",
    },
    {
        label: "Net Sales",
        columnSelector: "NET_SALES",
        dollarAmount: true,
        style: [CELL_TYPES.BOLD],
        tooltip:
            "The value under this column represents the total amount of sales collected from products under that category after discounts are taken into account.",
    },
    {
        label: "Tax",
        columnSelector: "TAXES",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the total amount of taxes collected from sales of products under that category during your selected time period.",
    },
    {
        label: "Total Collected",
        columnSelector: "TOTAL_COLLECTED",
        dollarAmount: true,
        style: [CELL_TYPES.BOLD],
    },
    {
        label: "Net Payment",
        columnSelector: "NET_TOTAL",
        dollarAmount: true,
        style: [CELL_TYPES.BOLD],
    },
];

export const MODIFIER_SALES_COLUMNS = [
    {
        label: "Order Count",
        columnSelector: "orderCount",
        tooltip:
            "The value under this column represents the total amount of times the modifier was applied to a product during your selected time period.",
    },
    {
        label: "Price",
        columnSelector: "price",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the cost of applying the modifier to a product.",
    },
    {
        label: "Total Modifier Sales",
        columnSelector: "totalSales",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the total amount collected from applying the modifier to products during your selected time period.",
    },
];

export const COGS_COLUMNS = [
    {
        label: "Sales",
        tooltip:
            "This column contains the sales of all products sold that used this ingredient.",
        columnSelector: "NET_SALES",
        dollarAmount: true,
        format: (val) => toDollars(Math.round(val), true),
    },
    {
        label: "Calculated COGS",
        tooltip:
            "This columns contains the caculated cost of all this ingredient sold during the time period.",
        columnSelector: "COGS",
        dollarAmount: true,
        format: (val) => toDollars(Math.round(val), true),
    },
    {
        label: "Gross Margin",
        tooltip:
            "This column represents your margin on your ingredients sold during this time period.",
        columnSelector: "NET_SALES",
        format: (_, row) => {
            if (row.NET_SALES === 0) {
                return "-";
            }

            return (
                <div className="text-right">
                    <div className="text-medium">{toDollars(row.GROSS_MARGIN, true)}</div>

                    <div className="text-gray-500">{`${(row.GROSS_MARGIN_PERCENTAGE * 100).toFixed(
                        2
                    )}%`}</div>
                </div>
            );
        },
    },
];

export const SALES_TO_COGS_COLUMNS = [
    {
        label: "Sales",
        tooltip:
            "This column contains the sales of all products sold that used this ingredient.",
        columnSelector: "NET_SALES",
        dollarAmount: true,
        format: (val) => toDollars(Math.round(val), true),
    },
    {
        label: "Estimated COGS",
        tooltip:
            "This columns contains the estimated cost of all the products sold during the time period.",
        columnSelector: "ESTIMATED_COGS",
        dollarAmount: true,
        format: (val) => toDollars(Math.round(val), true),
    },
    {
        label: "Calculated COGS",
        tooltip:
            "This columns contains the caculated cost of all this ingredient sold during the time period.",
        columnSelector: "COGS",
        dollarAmount: true,
        format: (val) => toDollars(Math.round(val), true),
    },
    {
        label: "Estimated Gross Margin",
        tooltip:
            "This column represents your margin on your ingredients sold during this time period.",
        columnSelector: "NET_SALES",
        format: (_, row) => {
            if (row.NET_SALES === 0) {
                return "-";
            }

            return (
                <div className="text-right">
                    <div className="text-medium">{toDollars(row.ESTIMATED_GROSS_MARGIN, true)}</div>

                    <div className="text-gray-500">{`${(row.ESTIMATED_GROSS_MARGIN_PERCENTAGE * 100).toFixed(
                        2
                    )}%`}</div>
                </div>
            );
        },
    },
    {
        label: "Calculated Gross Margin",
        tooltip:
            "This column represents your margin on your ingredients sold during this time period.",
        columnSelector: "NET_SALES",
        format: (_, row) => {
            if (row.NET_SALES === 0) {
                return "-";
            }

            return (
                <div className="text-right">
                    <div className="text-medium">{toDollars(row.GROSS_MARGIN, true)}</div>

                    <div className="text-gray-500">{`${(row.GROSS_MARGIN_PERCENTAGE * 100).toFixed(
                        2
                    )}%`}</div>
                </div>
            );
        },
    },
]

export const INGREDIENT_USAGE_COLUMNS = [
    {
        label: "Starting Inventory",
        tooltip:
            "This column lists the inventory of the ingredient at the beginning of the time period.",
        columnSelector: "OPENING_QUANTITY",
        format: (value, row) => {
            return (
                <div className="text-right">
                    <div className="text-medium">
                        {value
                            ? formatNumberCommas(
                                parseFloat(
                                    Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100
                                ).toFixed(2)
                            )
                            : 0}
                    </div>

                    <div className="text-gray-500">{toDollars(row.OPENING_COST, true)}</div>
                </div>
            );
        },
    },
    {
        label: "Ending Inventory",
        tooltip:
            "This column lists the inventory of the ingredient at the end of the time period.",
        columnSelector: "CLOSING_QUANTITY",
        format: (value, row) => {
            return (
                <div className="text-right">
                    <div className="text-medium">
                        {value
                            ? formatNumberCommas(
                                parseFloat(
                                    Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100
                                ).toFixed(2)
                            )
                            : 0}
                    </div>

                    <div className="text-gray-500">{toDollars(row.CLOSING_COST, true)}</div>
                </div>
            );
        },
    },
    {
        label: "Difference",
        tooltip:
            "This column lists how much of an ingredient was used during the time period. A positive value means the ingredient's stock increased. A negative value means the ingredient's stock decreased.",
        columnSelector: "ACTUAL_USAGE",
        format: (value, row) => {
            return (
                <div className="text-right">
                    <div className="text-medium">
                        {value
                            ? formatNumberCommas(
                                parseFloat(
                                    Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100
                                ).toFixed(2)
                            )
                            : 0}
                    </div>

                    <div className="text-gray-500">{toDollars(row.ACTUAL_COST, true)}</div>
                </div>
            );
        },
    },
    {
        label: "Cost of Goods Sold",
        tooltip:
            "This columns contains the cost of all this ingredient sold during the time period.",
        columnSelector: "COGS",
        dollarAmount: true,
        format: (val) => toDollars(Math.round(val), true),
    },
    {
        label: "Waste",
        tooltip:
            "This columns contains the cost of all this ingredient wasted during the time period.",
        columnSelector: "WASTE",
        dollarAmount: true,
        format: (val) => toDollars(Math.round(val), true),
    },
];

export const INGREDIENT_SALES_COLUMNS = [
    {label: "Ingredient SKU", columnSelector: "sku"},
    {label: "Unit Cost", columnSelector: "cost", dollarAmount: true},
    {
        label: "Sold Amount",
        columnSelector: "soldAmount",
        number: true,
        tooltip:
            "The value under this column represents the quantity of ingredients sold during your selected time frame.",
    },
    {
        label: "Total Cost",
        columnSelector: "totalCost",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the total amount sold multiplied by the unit cost for each ingredient.",
    },
];

export const INVENTORY_SALES_COLUMNS = [
    {
        label: "Amount Added",
        columnSelector: "added",
        number: true,
        tooltip:
            "The value under this column represents the amount of times the product's stock was added to during your selected time period.",
    },
    {
        label: "Amount Removed",
        columnSelector: "removed",
        number: true,
        tooltip:
            "The value under this column represents the amount of times the product's stock was reduced during your selected time frame.",
    },
];

export const PLATFORM_SALES_COLUMNS = [
    {
        label: "Order Count",
        columnSelector: "PAYMENT_COUNT",
        tooltip:
            "The value under this column represents the total amount of orders placed through the corresponding ordering platform within the specified time frame.",
    },
    {
        label: "Gross Sales",
        columnSelector: "GROSS_SALES",
        dollarAmount: true,
        style: [CELL_TYPES.BOLD],
        tooltip:
            "The value under this column represents the total amount of sales collected from orders placed under the corresponding order type, exluding taxes, refunds, or discounts.",
    },
    {
        label: "(Discounts)",
        columnSelector: "DISCOUNTS",
        dollarAmount: true,
        style: [CELL_TYPES.NEGATIVE],
        tooltip:
            "The value under this column represents the total amount that was discounted from sales placed through the corresponding ordering platform during your selected time period.",
    },
    {
        label: "Net Sales",
        columnSelector: "NET_SALES",
        dollarAmount: true,
        style: [CELL_TYPES.BOLD],
        tooltip:
            "The value under this column represents the total amount of sales collected after accounting for refunds and discounts.",
    },
    {
        label: "Card Tips",
        columnSelector: "CARD_TIPS",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the total amount of credit card tips collected through orders placed under the corresponding order type during your selected time period.",
    },
    {
        label: "Fees",
        columnSelector: "FEES_COLLECTED",
        dollarAmount: true,
    },
    {
        label: "Tax",
        columnSelector: "TAXES",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the total amount of taxes collected from orders placed under the corresponding order type during your selected time period.",
    },
    {
        label: "Total Collected",
        columnSelector: "NET_SALES",
        dollarAmount: true,
        style: [CELL_TYPES.BOLD],
    },
    {
        label: "(Refunds)",
        columnSelector: "REFUNDS",
        dollarAmount: true,
        style: [CELL_TYPES.NEGATIVE],
        tooltip:
            "The value under this column represents the total amount that was refunded from sales made through the corresponding ordering platform during your selected time period.",
    },
    {
        label: "(Processing)",
        columnSelector: "PROCESSING_FEES",
        dollarAmount: true,
        style: [CELL_TYPES.NEGATIVE],
        tooltip:
            "The value under this column represents the total amount of processing fees paid through orders placed under the corresponding order type during your selected time period.",
    },
    {
        label: "Net Total",
        columnSelector: "NET_TOTAL",
        dollarAmount: true,
        style: [CELL_TYPES.BOLD],
        tooltip:
            "The value under this column will represent the total amount of sales collected after processing fees have been deducted.",
    },
];
export const GIFT_CARD_SALES_COLUMNS = [
    {
        label: "Cards Sold",
        columnSelector: "ORDER_COUNT",
    },
    {
        label: "Card Sales",
        columnSelector: "GROSS_SALES",
        dollarAmount: true,
    },
    {
        label: "(Discounts)",
        columnSelector: "DISCOUNTS",
        dollarAmount: true,
    },
    {
        label: "Net Sales",
        columnSelector: "NET_SALES",
        dollarAmount: true,
    },
    {
        label: "Tax",
        columnSelector: "TAXES",
        dollarAmount: true,
    },
    {
        label: "Cards Total Collected",
        columnSelector: "TOTAL_COLLECTED",
        dollarAmount: true,
    },
    {
        label: "(Refunds)",
        columnSelector: "REFUNDS",
        dollarAmount: true,
    },
    {
        label: "Card Net Payment",
        columnSelector: "NET_TOTAL",
        dollarAmount: true,
    },
];

export const TIME_CARD_COLUMNS = [
    {
        label: "Hours Scheduled",
        columnSelector: "AMOUNT_HOURS_SCHEDULED",
        tooltip:
            "The value under this column represents the total amount of hours the corresponding employee was scheduled for during the selected time period.",
    },

    {
        label: "Tips Earned",
        columnSelector: "AMOUNT_TIPS",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the total amount of tips the corresponding employee collected during the shifts they worked over the course of your selected time period.",
    },
    {
        label: "Average Wage",
        columnSelector: "AVERAGE_WAGE",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the average wages collected by the corresponding employee during the hours they worked, including any tips they made.",
    },
];

export const TAXABLE_COLUMNS = [
    {
        label: "Net Sales",
        columnSelector: "NET_SALES",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the total amount of sales collected during your selected time period.",
    },
    {
        label: "Taxable Sales",
        columnSelector: "TAXABLE",
        tooltip:
            "The value under this column represents the total amount of taxable sales collected during your selected time frame.",
        dollarAmount: true,
    },
    {
        label: "Non-Taxable Sales",
        columnSelector: "NON_TAXABLE",
        tooltip:
            "The value under this column represents the total amount of non-taxable sales collected during your selected time period from sales of non-taxed products.",
        dollarAmount: true,
    },
    {
        label: "Third Party Sales",
        columnSelector: "THIRD_PARTY_NET_SALES",
        tooltip:
            "The value under this column represents the total amount of sales collected through third-party orders placed during your selected time period.",
        dollarAmount: true,
    },
    {
        label: "Tax Collected",
        columnSelector: "TAXES",
        tooltip:
            "The value under this column represents the total amount of taxes collected through taxable sales placed during your selected time period.",
        dollarAmount: true,
    },
];

export const TAX_CATEGORY_COLUMNS = [
    {
        label: "Tax Collected",
        columnSelector: "TAX_COLLECTED",
        tooltip: "The amount of tax collected using this tax rate.",
        dollarAmount: true,
    },
    {
        label: "Tax Rate",
        columnSelector: "TAX_RATE",
        tooltip: "The rate for this tax rate.",
        percentage: true,
    },
    {
        label: "Taxable Amount",
        columnSelector: "TAXABLE_AMOUNT",
        tooltip: "The amount of sales taxable using this tax rate.",
        dollarAmount: true,
    },
];
export const TAX_COLUMNS = [
    {label: "Tax Collected", columnSelector: "AMOUNT", dollarAmount: true},
];

export const DISCOUNT_SUMMARY_COLUMNS = [
    {
        label: "Quantity Used",
        columnSelector: "COUNT",
    },
    {
        label: "Total Discount",
        columnSelector: "AMOUNT_DISCOUNT",
        dollarAmount: true,
    },
];

export const LABOR_VS_SALES_COLUMNS = [
    {
        label: "Location",
        columnSelector: "LOCATION_NAME",
    },
    {
        label: "Labor Cost",
        columnSelector: "LABOR_COST",
        dollarAmount: true,
        tooltip: "This value represents the labor costs over the specified time period.",
    },
    {
        label: "Sales",
        columnSelector: "SALES",
        dollarAmount: true,
        tooltip: "This value represents the sales over the specified time period.",
    },
    {
        label: "Percentage",
        columnSelector: "LABOR_VS_SALES_PERCENTAGE",
        percentage: true,
        tooltip:
            "This value represents the ratio between you labor costs and sales amounts as a percentage.",
    },
];

export const CASH_DRAWERS_COLUMNS = [
  {
    label: "Close Time",
    columnSelector: "DATE_CLOSE",
    csvFormat: (value, row) => {
      return moment(value).format("MMM DD, hh:mm A");
    },
    format: (value) => {
      if (value) {
        return moment(value).format("MMM DD, hh:mm A");
      }
    },
    tooltip: "The time the cash drawer was closed.",
  },
  {
    label: "Verification Time",
    columnSelector: "DATE_VERIFIED",
    csvFormat: (value, row) => {
      return moment(value).format("MMM DD, hh:mm A");
    },
    format: (value) => {
      if (value) {
        return moment(value).format("MMM DD, hh:mm A");
      }
    },
    tooltip: "The time the cash drawer was verified.",
  },
  {
    label: "Device",
    columnSelector: "DEVICE_NAME",
    tooltip:
      "The name of the POS device that the corresponding cash drawer was opened on.",
  },
  {
    label: "Opening Amount",
    columnSelector: "AMOUNT_OPEN",
    dollarAmount: true,
  },
  {
    label: "Cash Sales",
    columnSelector: "TOTAL_COLLECTED",
    dollarAmount: true,
  },
  {
    label: "Paid In/Out",
    columnSelector: "PAYIN_TOTAL",
    dollarAmount: true,
    tooltip:
      "Total cash payins or payouts recorded on this drawer.",
  },
  {
    label: "Expected Amount",
    columnSelector: "AMOUNT_EXPECTED",
    dollarAmount: true,
    tooltip:
      "This value is the sum of the corresponding drawer's opening amount, total cash sales, and pay ins, minus any pay outs.",
  },
  {
    label: "Closing Amount",
    columnSelector: "AMOUNT_CLOSE",
    dollarAmount: true,
    tooltip:
      "The closing amount of cash recorded in the corresponding drawer once it has been closed.",
  },
  {
    label: "Close Difference",
    columnSelector: "DIFFERENCE",
    dollarAmount: true,
    tooltip:
      "The amount closed minus the amount expected, without payins/payouts.",
  },
  {
    label: "Verified Amount",
    columnSelector: "AMOUNT_VERIFIED",
    dollarAmount: true,
    tooltip:
      "The actual verified amount of cash recorded in the corresponding drawer.",
  },
  {
    label: "Verified Difference",
    columnSelector: "VERIFIED_DIFFERENCE",
    dollarAmount: true,
    tooltip: "The difference between the verified amount and the actual closed amount.",
  },
  {
    label: "Deposit",
    columnSelector: "AMOUNT_DEPOSIT",
    dollarAmount: true,
    tooltip: "The expected amount minus the reported closing amount.",
  },
  {
    label: "Status",
    columnSelector: "DATE_OPEN",
    tooltip: "This column lists if the drawer is either opened or closed.",
    csvFormat: (value, row) => {
      if (!row.ID) {
        return <div></div>;
      }

      if (row?.DATE_VERIFIED) {
        return "Verified";
      } else if (row?.DATE_CLOSE) {
        return "Closed";
      } else {
        return "Open";
      }
    },
    format: (value, row) => {
      if (!row.ID) {
        return <div></div>;
      }

      if (row?.DATE_VERIFIED) {
        return <SuccessBadge>Verified</SuccessBadge>;
      } else if (row?.DATE_CLOSE) {
        return <DangerBadge>Closed</DangerBadge>;
      } else {
        return <WarningBadge>Open</WarningBadge>;
      }
    },
  },
];

export const CASH_FLOW_COLUMNS = [
    {
        label: "Starting Cash",
        columnSelector: "AMOUNT_OPEN",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the amount of cash recorded in the corresponding drawers when first opened.",
    },
    {
        label: "Pay In / Out",
        columnSelector: "PAYIN_TOTAL",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the total amount of cash pay ins and pay outs recorded across your cash drawers.",
    },
    {
        label: "Cash Sales",
        columnSelector: "CASH_SALES",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the total amount of cash sales collected under each drawer.",
    },
    {
        label: "Expected in Drawer",
        columnSelector: "AMOUNT_EXPECTED",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the total amount of cash expected to be in the corresponding drawer once it is closed. This is equal to the opening amount plus pay in events and cash sales, and minus any pay out events.",
    },
    {
        label: "Cash Retained",
        columnSelector: "CASH_REMAINING",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the total amount of cash that was left in the drawer after it was closed. This is entered alongside the close amount.",
    },
    {
        label: "Amount Close",
        columnSelector: "AMOUNT_CLOSE",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the closing amount as entered by the employee that closed the drawer.",
    },
    {
        columnSelector: "OPEN_CLOSE_DIFFERENCE",
        label: "Excess Cash on Hand",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the closing amount minus the amount of cash retained.",
    },
    {
        columnSelector: "BANK_PAYIN_TOTAL",
        label: "Bank Pay In / Out",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the sum of all pay in and pay out events made to the bank.",
    },
    {
        label: "Expected Deposit",
        columnSelector: "EXPECTED_DEPOSIT",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the deposit the system expects. This is equal to expected in bank value minus the amount of cash retained.",
    },
    {
        label: "Actual Deposit",
        columnSelector: "AMOUNT_DEPOSIT",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the deposit that the employee entered when they closed the bank.",
    },
    {
        label: "Deposit Differential",
        columnSelector: "DEPOSIT_DIFFERENTIAL",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the difference between the actual deposit and the expected deposit.",
    },
];

export const INVOICING_COLUMNS = [
    {
        label: "Invoice",
        columnSelector: "FIRST_NAME",
        tooltip: "This column will list the name and number of the invoice.",
        style: [CELL_TYPES.FLOAT_LEFT],
        format: (_, invoice) =>
            !!invoice.INVOICE_NUMBER ? (
                <div className={"flex flex-col justify-start"}>
                    <div className={"font-bold"}>
                        {invoice.FIRST_NAME} {invoice.LAST_NAME}
                    </div>

                    <div className={"font-md text-sm"}>#{invoice.INVOICE_NUMBER}</div>
                </div>
            ) : (
                <div></div>
            ),

        csvFormat: (_, invoice) =>
            invoice.FIRST_NAME + " " + invoice.LAST_NAME + " (" + invoice.INVOICE_NUMBER + ")",
    },
    {
        label: "Location",
        columnSelector: "LOCATION_NAME",
        tooltip: "This column will list the location from which the invoice was sent.",
    },
    {
        label: "Date Paid",
        columnSelector: "PAID_DATE",
        tooltip: "This column will list the day on which the invoice was paid.",
    },
    {
        label: "Taxable Sales",
        columnSelector: "TAXABLE_SALES",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the total amount of sales collected from taxed products included in the invoice.",
    },
    {
        label: "Nontaxable Sales",
        columnSelector: "UNTAXABLE_SALES",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the total amount of sales collected from non-taxed products included in the invoice.",
    },
    {
        label: "Discounts",
        columnSelector: "AMOUNT_DISCOUNT",
        dollarAmount: true,
        tooltip:
            "The value under this column represents the amount that was discounted from products included in the invoice.",
        format: (val) => "(" + toDollars(val, true) + ")",
    },
    {
        label: "Net Sales",
        columnSelector: "NET_SALES",
        tooltip:
            "The value under this column represents the total cost of the invoice after taking discounts into account.",
        dollarAmount: true,
    },
    {
        label: "Tips",
        columnSelector: "AMOUNT_TIP",
        tooltip: "The value under this column represents the tips added onto the invoice.",
        dollarAmount: true,
    },
    {
        label: "Service Fees",
        columnSelector: "AMOUNT_FEES",
        tooltip:
            "The value under this column represents any service fees included in the invoice.",
        dollarAmount: true,
    },
    {
        label: "Taxes",
        columnSelector: "AMOUNT_TAX",
        tooltip:
            "The value under this column represents the total amount of taxes collected from sales included in the invoice.",
        dollarAmount: true,
    },
    {
        label: "Refunds",
        columnSelector: "REFUNDS",
        tooltip:
            "The value under this column represents the amount that was refunded from the invoice.",
        format: (val) => "(" + toDollars(val, true) + ")",
    },
    {
        label: "Processing Fees",
        columnSelector: "AMOUNT_PROCESSING",
        tooltip:
            "The value under this column represents the total amount of processing fees collected when the invoice was paid.",
        format: (val) => "(" + toDollars(val, true) + ")",
    },
    {
        label: "Total",
        columnSelector: "TOTAL",
        tooltip:
            "The value under this column represents the total cost of the invoice after taking tips, service fees, taxes, refunds, and processing fees into account.",
        dollarAmount: true,
    },
];

export const CHECKLIST_COLUMNS = [
    {
        label: "On Time",
        columnSelector: "ON_TIME_PERCENTAGE",
        format: (val, row) => {
            return row.ON_TIME_COUNT + " (" + row.ON_TIME_PERCENTAGE + ")";
        },
    },
    {
        label: "Late",
        columnSelector: "LATE_PERCENTAGE",
        format: (val, row) => {
            return row.LATE_COUNT + " (" + row.LATE_PERCENTAGE + ")";
        },
    },
    {
        label: "Outstanding",
        columnSelector: "OUTSTANDING_PERCENTAGE",
        format: (val, row) => {
            return row.OUTSTANDING_COUNT + " (" + row.OUTSTANDING_PERCENTAGE + ")";
        },
    },
    {
        label: "Overdue",
        columnSelector: "OVERDUE_PERCENTAGE",
        format: (val, row) => {
            return row.OVERDUE_COUNT + " (" + row.OVERDUE_PERCENTAGE + ")";
        },
    },
];

export const CUSTOM_PAYMENT_TYPE_TIPS_COLUMNS = [
    {
        label: "Tip",
        columnSelector: "AMOUNT_TIPS",
        dollarAmount: true,
    },
];

export function doLocationsHaveDoubleOvertime(location, payrollGroup) {
    if (payrollGroup) {
        return payrollGroup.LOCATIONS_IN_GROUP.filter(({STATE}) => STATE).some(
            ({STATE}) => STATE_OVERTIME_RULES[STATE]?.DAILY_DOUBLE
        );
    }

    return STATE_OVERTIME_RULES[location.STATE]?.DAILY_DOUBLE;
}

export const STATE_OVERTIME_RULES = {
    AL: {WEEKLY: true},
    AK: {
        WEEKLY: true,
        DAILY: true,
    },
    AZ: {
        WEEKLY: true,
    },
    AR: {WEEKLY: true},
    CA: {
        DAILY: true,
        DAILY_DOUBLE: true,
        SEVENTH_DAY: true,
        WEEKLY: true,
    },
    CO: {
        DAILY: true,
        WEEKLY: true,
    },
    CT: {WEEKLY: true},
    DE: {WEEKLY: true},
    DC: {WEEKLY: true},
    FL: {WEEKLY: true},
    GA: {WEEKLY: true},
    HI: {WEEKLY: true},
    ID: {WEEKLY: true},
    IL: {WEEKLY: true},
    IN: {WEEKLY: true},
    IA: {WEEKLY: true},
    KS: {WEEKLY: true},
    KY: {
        SEVENTH_DAY: true,
        WEEKLY: true,
    },
    LA: {WEEKLY: true},
    ME: {WEEKLY: true},
    MD: {WEEKLY: true},
    MA: {WEEKLY: true},
    MI: {WEEKLY: true},
    MN: {WEEKLY: true},
    MS: {WEEKLY: true},
    MO: {WEEKLY: true},
    MT: {WEEKLY: true},
    NE: {WEEKLY: true},
    NV: {
        DAILY: true,
        WEEKLY: true,
    },
    NH: {WEEKLY: true},
    NJ: {WEEKLY: true},
    NM: {WEEKLY: true},
    NY: {WEEKLY: true},
    NC: {WEEKLY: true},
    ND: {WEEKLY: true},
    OH: {WEEKLY: true},
    OK: {WEEKLY: true},
    OR: {WEEKLY: true},
    PA: {WEEKLY: true},
    RI: {WEEKLY: true},
    SC: {WEEKLY: true},
    SD: {WEEKLY: true},
    TN: {WEEKLY: true},
    TX: {WEEKLY: true},
    UT: {WEEKLY: true},
    VT: {WEEKLY: true},
    VA: {WEEKLY: true},
    WA: {WEEKLY: true},
    WV: {WEEKLY: true},
    WI: {WEEKLY: true},
    WY: {WEEKLY: true},
};

export const TAX_WITHHOLDING_PAYOUT_ROWS = [
    {
        label: "Withholdings Paid Out",
        selector: "AMOUNT_WITHHELD",
    },
    {
        label: "Tax",
        style: [CELL_TYPES.SECONDARY],
        selector: "AMOUNT_TAX_WITHHELD",
    },
    {
        label: "Fees",
        style: [CELL_TYPES.SECONDARY],
        selector: "AMOUNT_PROCESSING_WITHHELD",
    },
    {
        label: "Fees",
        style: [CELL_TYPES.NEGATIVE],
        selector: "AMOUNT_PROCESSING",
    },
    {
        label: "Payout Total",
        style: [CELL_TYPES.BOLD],
        selector: "TOTAL",
    },
];

export const DAILY_PAYOUT_ROWS = [
    {
        label: "Gross Sales",
        selector: "GROSS_SALES",
        rowType: CELL_TYPES.BOLD,
        dollarAmount: true,
        style: [CELL_TYPES.BOLD],
    },
    {
        label: "Discounts",
        selector: "DISCOUNTS",
        rowType: CELL_TYPES.NEGATIVE,
        dollarAmount: true,
        style: [CELL_TYPES.NEGATIVE],
    },
    {
        label: "Net Sales",
        selector: "NET_SALES",
        rowType: CELL_TYPES.BOLD,
        dollarAmount: true,
        style: [CELL_TYPES.BOLD],
    },
    {
        label: "Tips",
        selector: "CARD_TIPS",
        rowType: CELL_TYPES.REGULAR,
        dollarAmount: true,
        style: [CELL_TYPES.REGULAR],
    },
    {
        label: "Collected Fees",
        selector: "FEES_COLLECTED",
        rowType: CELL_TYPES.REGULAR,
        dollarAmount: true,
        style: [CELL_TYPES.REGULAR],
    },
    {
        label: "Taxes",
        selector: "TAXES",
        rowType: CELL_TYPES.REGULAR,
        dollarAmount: true,
        style: [CELL_TYPES.REGULAR],
    },
    {
        label: "Total Collected",
        selector: "TOTAL_COLLECTED",
        rowType: CELL_TYPES.BOLD,
        dollarAmount: true,
        style: [CELL_TYPES.BOLD],
    },
    {
        label: "Refunds",
        selector: "REFUND_AMOUNT",
        rowType: CELL_TYPES.NEGATIVE,
        dollarAmount: true,
        style: [CELL_TYPES.NEGATIVE],
    },
    {
        label: "Fees",
        selector: "PROCESSING_FEES",
        rowType: CELL_TYPES.NEGATIVE,
        dollarAmount: true,
        style: [CELL_TYPES.NEGATIVE],
    },
    {
        label: "Net Total",
        selector: "NET_TOTAL",
        rowType: CELL_TYPES.BOLD,
        dollarAmount: true,
        style: [CELL_TYPES.BOLD],
    },
    {
        label: "Payout Fee",
        selector: "PAYOUT_FEE",
        rowType: CELL_TYPES.NEGATIVE,
        dollarAmount: true,
        style: [CELL_TYPES.NEGATIVE],
    },
    {
        label: "Payout",
        selector: "PAYOUT_TOTAL",
        rowType: CELL_TYPES.BOLD,
        dollarAmount: true,
        style: [CELL_TYPES.BOLD],
    },
];

// export const DAILY_PAYOUT_ROWS = [
//   {
//     label: "Card Payments",
//     selector: "AMOUNT_PAYMENTS",
//     persist: true,
//   },
//   {
//     label: "Withholdings",
//     style: [CELL_TYPES.NEGATIVE],
//     selector: "AMOUNT_WITHHELD",
//   },
//   {
//     label: "Tax Withheld",
//     style: [CELL_TYPES.SECONDARY, CELL_TYPES.NEGATIVE],
//     selector: "AMOUNT_TAX_WITHHELD",
//   },
//   {
//     label: "Payout Fees Withheld",
//     style: [CELL_TYPES.SECONDARY, CELL_TYPES.NEGATIVE],
//     selector: "AMOUNT_PROCESSING_WITHHELD",
//   },
//   {
//     label: "Withholding Adjustment",
//     style: [CELL_TYPES.SECONDARY, CELL_TYPES.NEGATIVE],
//     selector: "AMOUNT_WITHHOLDING_ADJUSTED",
//   },
//   {
//     label: "Fees",
//     style: [CELL_TYPES.NEGATIVE],
//     selector: "AMOUNT_PROCESSING",
//   },
//   {
//     label: "Payout Total",
//     style: [CELL_TYPES.BOLD],
//     selector: "TOTAL",
//   },
// ];

export const EXTRA_DATA_COLUMN = {
    label: "Other Info",
    key: "EXTRA_DATA",
};
